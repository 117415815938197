import { Breadcrumbs, Card, Grid, Link, Typography } from "@mui/material";
import React from "react";
import Header from "../Component/HomePage/Header";
import Fotter from "../Component/Footer";

function Index() {
  return (
    <>
      <Header />

      <Grid
        container
        sx={{
          background:
            "linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 40%)",
            px: { xs: 4, md: 4, lg: 18 },
        }}
      >
        <Grid container pt={6}>
          <Grid item xs={12} md={12} sx={{ margin: "auto 0" ,pb:2 }}>
            <Typography
              sx={{
                fontSize: "45px",
                fontFamily: "Ubuntu",
                fontWeight: 500,
                // pt: 4,
                textAlign: "center",
              }}
            >
              Terms Of Use
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
            <Card sx={{ p: 4 }}>
              <Typography
                sx={{ fontFamily: "Ubuntu", fontWeight: 600, fontSize: "20px" }}
              >
                Terms and conditions
              </Typography>
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: "15px" ,pt:2}}>
                “Arham” refers to Arham Share Consultants Pvt Ltd. & Arham
                Commodities Brokers Pvt Ltd. (Hereinafter Referred to As
                ‘ARHAM’) and its affiliates with registered office at 15th
                Floor, Unit No. 1B, GIFT One Building, Village Ratanpur, Taluka
                Gandhinagar-382355, Being A Trading Member Of NSE And BSE,
                Registered With Securities & Exchange Board Of India ("SEBI")
                And Having Registration No. INZ000175534 for
                Cash/Derivatives/Currency Derivatives Segments Of NSE & BSE.
                Commodity Trading On Your Account Will Be Enabled Through Arham
                Commodities Brokers Pvt Ltd., A Member Of MCX Registration No.
                55480) And Having SEBI Registration Number INZ000085333. The
                Depository Services For Your Account Will Be Enabled Through
                Arham Share Consultants Pvt Ltd. A Depository Participant Using
                CDSL As The Depository Having SEBI Registration No.:
                IN-DP-252-2016.
                <br />
                <br /> The “Client” is any individual or entity with an exchange
                approved UCC (Unique Client Code) account with Arham, able to
                trade various securities through Arham (in compliance with
                various exchange and SEBI regulations).
                <br />
                <br />
                XTS API is a suite of APIs, developer services, and associated
                software offerings collectively referred to as “APIs”. The APIs
                provide programmatic access (including, but not limited to:
                placing, modifying and cancelling orders; managing funds and
                portfolios; accessing order and account related information
                etc.) to various exchanges via Arham using a Client account
                <br />
                <br />
                For Terms and conditions of developer visit:
                <a
                  href="https://symphonyfintech.com/general-terms-conditions/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://symphonyfintech.com/general-terms-conditions/
                </a>
                <br />
                <br />
                For API documentation visit:
                <a
                  href=" https://symphonyfintech.com/xts-trading-front-end-api/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  https://symphonyfintech.com/xts-trading-front-end-api/
                </a>
                <br />
                <br />
                The terms described in this document, and any additional terms,
                terms within the accompanying API documentation, and any
                applicable policies and guidelines as concerning the use of the
                APIs are collectively referred to as the “Terms”. <br />
                <br />
                This agreement is between “You” (accessor and consumer of the
                APIs) and “Arham”, the stock broker offering broking services
                via the APIs. You may be an Arham Client yourself, or you may be
                an entity offering the APIs in turn to other Arham Clients as
                end users.
                <br />
                <br /> Please Note That The Information Contained Herein Is
                Subject To Change Without Notice. <br />
                <br />
                The “Client” is any individual or entity with an exchange
                approved UCC (Unique Client Code) account with Arham, able to
                trade various securities through Arham (in compliance with
                various exchange and SEBI regulations). <br />
                <br />
                The terms described in this document, and any additional terms,
                terms within the accompanying API documentation, and any
                applicable policies and guidelines as concerning the use of the
                APIs are collectively referred to as the “Terms”. <br />
                <br />
                This agreement is between “You” ( accessor and consumer of the
                APIs) and “Arham”, the stock broker offering broking services
                via the APIs. You may be a Arham Client yourself, or You may be
                an entity offering the APIs in turn to other Arham Clients as
                end users.
                <br />
                <br />
                <b>Subsidiaries and Affiliates:</b> Arham has subsidiaries and
                affiliated legal entities throughout India. These companies may
                provide the APIs to You on behalf of Arham and the terms will
                also govern your relationship with these companies.
                <br />
                <br />
                <b>1) Account and registration </b>
                <br />
                <br />
                <b>a) Accepting the Terms </b>
                <br />
                <br />
                You may not use the APIs and may not accept the Terms if <br />
                <br />
                (a) You are not of legal age to form a binding contract with
                Arham, or (b) You are a person barred from using or receiving
                the APIs under the applicable laws of India or other countries
                including the country in which You are resident or from which
                You use the APIs.
                <br />
                <br /> <b> b) Entity Level Acceptance</b>
                <br />
                <br />
                If You are using the APIs on behalf of an entity, You represent
                and warrant that You have authority to bind that entity to the
                Terms and by accepting the Terms, You are doing so on behalf of
                that entity (and all references to “you” in the Terms refer to
                that entity). <br />
                <br /> <b>c) Registration </b>
                <br />
                <br />
                In order to access certain APIs You may be required to provide
                certain information (such as identification or contact details)
                as part of the registration process for the APIs, or as part of
                your continued use of the APIs. Any registration information you
                give to Arham will always be accurate and up to date and you’ll
                inform us promptly of any updates. <br />
                <br /> <b>2) API usage</b>
                <br />
                <br /> The APIs are meant to be used for building innovative
                investment and trading platforms (“IBT” - Internet Based
                Trading) with the greater goal of increasing capital market
                participation in India. This may be for personal use, where You,
                a Client, developers a private interface exclusively for
                customising personal trading and investment experience, or this
                may for building a trading platform which in term will be
                offered to the public, other Clients of Arham. Development of
                trading platforms are bound by various norms and regulations
                stipulated by various exchanges and SEBI. You are responsible
                for ensuring You adhere to these platform guidelines and
                regulations, and seeking appropriate regulatory approvals if
                necessary. If you seek to obtain exchange approvals for your
                platform as Arham’s in-house platform, Arham will provide the
                necessary assistance. <br />
                <br /> <b>a) Permitted access </b>
                <br />
                <br />
                You will only access (or attempt to access) an API by the means
                described in the documentation of that API. Any circumvention of
                the methods described in the documentation may result in the
                termination of your access to the APIs. <br />
                <br /> <b>b) API rate-limiting</b>
                <br />
                <br />
                Arham may set limits on your use of the APIs (for instance,
                limiting the number of requests sent to a particular API)
                manually or via automated checks to ensure the stability of the
                APIs. If you need to use the APIs beyond the limits imposed by
                Arham, You have to obtain express consent from Arham by writing
                to the relevant department (Arham may decline or accept such a
                request based on various factors).
                <br />
                <br /> <b> c) Exclusivity</b>
                <br />
                <br /> The API Terms are non-exclusive. You acknowledge that
                Arham may develop products or services that may be similar and
                may be in direct or indirect competition with any platform that
                is built using the APIs. <br />
                <br /> <b>d) Tracking and monitoring </b>
                <br />
                <br />
                By using the APIs, You agree that Arham may monitor, track, and
                record all interactions and requests happening via the API, your
                own and your end users, for the purpose of ensuring stability,
                security, and reliability of the API.
                <br />
                <br /> <b> e) Automated trades</b>
                <br />
                <br /> The APIs are not meant for placing fully automating
                trades (without manual intervention). If you wish to use the
                APIs for full automation, you should seek necessary approvals
                from the exchange. Arham can provide the necessary assistance in
                obtaining approvals. <br />
                <br /> <b>3) Your end users</b>
                <br />
                <br /> You may use the APIs to build platforms which you may in
                turn offer to other Clients of Arham (after obtaining the
                required exchange approvals). Neither Arham nor anyone
                affiliated with the APIs may be held responsible for the losses
                suffered by the client or you, arising from technical
                malfunctions of your platform or the APIs. Your end users,
                Arham’s Clients, are bound primarily by the terms and conditions
                they agreed to at the time of their account registration with
                Arham, over and above the terms they may have agreed to when
                starting to use your platforms. <br />
                <br /> <b>a) Ownership</b>
                <br />
                <br /> As the APIs are restricted to–and only work with–the
                accounts of Arham clients, all end users you offer your
                API-enabled platforms to, are Arham’s Clients. Any new user that
                you may refer to Arham (who in turn opens account an account
                with Arham on their own volition) for the purpose of offering
                your platform, is also Arham’s Client. While you may promote
                your services to such a Client, You may not claim any
                exclusivity to the Client or their data. Arham may offer any
                service or offering to any of its existing clients, including
                Clients referred by you, at any time.
                <br />
                <br /> <b> b) User Privacy</b>
                <br />
                <br /> You will comply with all the applicable privacy laws and
                regulations including those related to your end users. You will
                adhere to Arham’s privacy policy and provide a privacy policy
                for your end user that is clear and accurate.
                <br />
                <br /> <b> 4) Prohibitions and confidentiality </b>
                <br />
                <br /> <b>a) API Prohibitions </b>
                <br />
                <br />
                When using the APIs, you may not (or allow those acting on your
                behalf to):
                <br />
                <ul>
                  <li>Sublicense the APIs for use by a third party.</li>
                  <li>
                    Perform an action with the intent of introducing to Arham’s
                    products and services, any viruses, worms, defects, Trojan
                    horses, malware, or any items of destructive nature.
                  </li>
                  <li>Defame abuse, harass, stalk, or threaten others.</li>
                  <li>
                    Interfere with or disrupt the APIs or the servers or
                    networks providing the APIs
                  </li>
                  <li>
                    Promote or facilitate unlawful online gambling or disruptive
                    commercial messages or advertisements.
                  </li>
                  <li>
                    Reverse engineer or attempt to extract the source code from
                    any API or any related software, except to the extent that
                    this restriction is expressly prohibited by applicable law
                  </li>
                  <li>
                    Use the APIs to process or store any data that will in turn
                    invade the privacy of the end user.
                  </li>
                  <li>
                    Remove, obscure, or alter any Arham terms of service or any
                    links to or notices of those terms
                  </li>
                </ul>
                <b>b) Confidentiality </b> <br />
                <br />
                Your credentials (such as passwords, API keys and secrets, and
                Client IDs) are intended to be used only by you. You will keep
                your credentials confidential and make reasonable efforts to
                prevent and discourage other API Clients from using your
                credentials. <br />
                <br /> Arham’s communications to you may contain confidential
                information which includes any materials, communications, and
                information that are marked confidential or that would normally
                be considered confidential under the circumstances. If you
                receive any such information, then you will not disclose it to
                any third party without Arham’s prior written consent. <br />
                <br />
                Unless expressly permitted by Arham or by the applicable laws,
                you will not, and will not permit your end users or others
                acting on your behalf to, do the following with content returned
                from the APIs. <br />
                <br />
                <ul>
                  <li>
                    Scrape, build databases, or otherwise create permanent
                    copies of such content, or keep cached copies with the
                    intent of redistributing.
                  </li>
                  <li>
                    {" "}
                    translate, modify, create a derivative work of, sell, lease,
                    lend, convey, distribute, publicly display, or sublicense to
                    any third party.
                  </li>
                  <li>Misrepresent the source or ownership; or</li>
                  <li>
                    {" "}
                    Remove, obscure, or alter any copyright, trademark, or other
                    proprietary rights notices; or falsify or delete any author
                    attributions, legal notices, or other labels of the origin
                    or source of material.
                  </li>
                </ul>
                <b> 5) Costs and revenue</b> <br />
                <br />
                <b>a) Costs</b>
                <br /> Arham may charge you for using the APIs, or parts of it
                selectively. These charges may be fixed or variable, and are at
                the sole discretion of Arham. Arham may also propose additional
                charges to you, (based on your usage of the APIs and on a
                case-by-case basis) for the continued use of the APIs. You may
                write to the relevant department to discuss a pricing suitable
                for you (Arham may decline or accept such a request based on
                various factors). <br />
                <br />
                <b> b) Non-refundable charges</b>
                <br />
                <br />
                Any charges once paid (one-off or on-going) for the use of APIs
                or any of its subsets are nonrefundable. <br />
                <br />
                <b> c) Sharing of revenue </b>
                <br />
                <br />
                Arham may share revenue with you (bound by the terms set forward
                by various exchanges and SEBI) for referring clients to Arham
                via the API, or for offering other services. You may write to
                the relevant department to discuss a revenue sharing model you
                desire (Arham may decline or accept such a request based on
                various factors).
                <br />
                <br />
                <b> 6) Liability of the APIs</b>
                <br />
                <br /> <b>a) Uptime</b>
                <br />
                <br /> The API response and account access times may vary due to
                a variety of factors, including, but not limited to, trading
                volumes, market conditions, system performance, technical and
                other factors. Market volatility, volume and system availability
                may delay account access and trade executions. The uptime and
                availability of the APIs may be affected by technical or other
                factors within or outside of Arham’s control, or unforseen
                circumstances including natural disasters. <br />
                <br />
                <b> b) No guarantees or warranties </b>
                <br />
                <br />
                Arham does not make any promises, commitments, and guarantees
                about the APIs or related offerings. When permitted by law,
                Arham, will not be responsible for lost profits, revenues, or
                data; financial losses; or indirect, special, consequential,
                exemplary, or punitive damages. To the extent permitted by law,
                Arham excludes all warranties, guarantees, conditions,
                representations, and undertakings. <br />
                <br />
                <b>c) Limitation of Liability</b>
                <br />
                <br />
                When permitted by law, Arham will not be responsible for lost
                profits, revenues, or data; financial losses; or indirect,
                special, consequential, exemplary, or punitive damages. In all
                cases, Arham will not be held liable for expense, loss or damage
                that is not reasonably foreseeable.
                <br />
                <br /> <b>7) Termination</b> <br />
                <br />
                <b>a) Termination </b> <br />
                <br />
                This agreement is effective until terminated. User rights under
                this agreement will terminate automatically or otherwise cease
                to be effective without notice from Arham if user fails to
                comply with any terms and conditions under this agreement. Arham
                may terminate this agreement if User is in default of any of the
                terms and conditions of this agreement and fails to correct such
                default within ten (10) days after written notice thereof from
                Arham. Upon termination, Arham does not hold any liability or
                other obligation to you. <br />
                <br />
                If you want to terminate the Terms, you must provide Arham with
                prior written notice and upon termination, cease your use of the
                applicable APIs, use of Arham brand and delete any cached or
                stored content that was permitted by the cache header. <br />
                <br />
                <b> b) Surviving provisions </b>
                <br />
                <br />
                When the Terms come to an end, those terms that by their nature
                are intended to continue indefinitely will continue to apply.
                <br />
                <br /> <b>8) Indemnification</b>
                <br />
                <br /> You agree to indemnify and hold Arham and their parent
                companies, members, subsidiaries, affiliates, service providers,
                syndicators, distributors, licensors, officers, directors and
                employees, against all liabilities, damages, losses, costs, fees
                (including legal fees) and expenses relating to any allegation
                or third – party legal proceeding to the extent arising from:{" "}
                <ul>
                  <li> Technical faults or the limitations of the APIs.</li>
                  <li>Your misuse or your end user’s misuse of the APIs.</li>
                  <li>
                    Your violation or your end user’s violation of the Terms; or
                  </li>
                  <li>
                    Any content or data routed into or used with the APIs by
                    you, those acting on your behalf, or your end users.{" "}
                  </li>
                </ul>
                <br />
                <b>9) Governing law/forum</b>
                <br />
                <br /> This Agreement shall be governed and interpreted by the
                laws of the Union of India. Only courts in Bengaluru (Karnataka)
                shall have the jurisdiction for the resolution of any disputes
                hereunder. Both parties hereby consent to such personal and
                exclusive jurisdiction.
              </Typography>
              <Typography sx={{ fontFamily: "Ubuntu", fontSize: "14px" ,py:2,fontWeight:600}}>
                THIS AGREEMENT REQUIRES ACCEPTANCE TO PROCEED TO UTILISATION OF
                THE APIs; THE ACCEPTANCE SO GIVEN CONCLUDES THE AGREEMENT AND
                THIS AGREEMENT DOES NOT REQUIRE A PHYSICAL/DIGITAL SIGNATURE.
                THIS AGREEMENT SHALL NOT BE INVALIDATED SOLELY ON THE GROUND
                THAT IT IS NOT PHSYICALLY SIGNED
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Fotter />
    </>
  );
}

export default Index;
