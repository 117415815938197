import {
  Box,
  Grid,
  Link,
  styled,
  TextField,
  Typography,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useState } from "react";
import ArhamFooter from "../../assets/images/logo/ArhamFooter.png";
import Facebook from "../../assets/images/Icon/Facebook.png";
import instagram from "../../assets/images/Icon/instagram.png";
import LinkedinFooter from "../../assets/images/Icon/LinkedinFooter.png";
import YouTube from "../../assets/images/Icon/YouTube.png";
import Email from "../../assets/images/logo/Email.png";
import Phone from "../../assets/images/logo/Phone.png";
import Location from "../../assets/images/logo/Location.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Account from "../../Account/index";
import axios from "axios";
import SebiScore from "./SebiScore";

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer; // Ensure cursor changes to pointer on hover
`;
const CustomTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid white !important",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      border: "1px solid white !important",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid white !important",
      borderRadius: "8px",
    },
  },
  "& .MuiInputLabel-root": {
    color: "white",
    fontSize: "15px",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "white",
    fontSize: "15px",
  },
  "& .MuiInputBase-input": {
    color: "white",
    fontSize: "15px",
  },
}));

function Footer() {
  const [openSebiDialog, setOpenSebiDialog] = useState(false);

  const handleSebiClick = () => {
    setOpenSebiDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenSebiDialog(false);
  };

  const tableData = [
    {
      companyName: "Mr. Naresh M. Mehta",
      Designation: "CEO/MD",
      MobileNo: "0261-6794032",
    },
    {
      companyName: "Mr. Priyank A. Mehta ",
      Designation: "Whole Time Director",
      MobileNo: "0261-6794030",
    },
    {
      companyName: "Mr. Priyank A. Mehta ",
      Designation: "Compliance officer",
      MobileNo: "0261-6794030",
    },
    {
      companyName: "Mr. Priyank A. Mehta",
      Designation: "Designated Director-1",
      MobileNo: "0261-6794030",
    },
  ];

  const bankData = [
    {
      accountNo: "00670340001650",
      ifsc: "HDFC0000067",
      name: "HDFC Bank Ltd.",
    },
    {
      accountNo: "00670340001633",
      ifsc: "HDFC0000067",
      name: "HDFC Bank Ltd.",
    },
    {
      accountNo: "00670340001640",
      ifsc: "HDFC0000067",
      name: "HDFC Bank Ltd.",
    },
    // {
    //   accountNo: "00670340001736",
    //   ifsc: "HDFC0000067",
    //   name: "HDFC Bank Ltd.",
    // },
    // {
    //   accountNo: "57500000492219",
    //   ifsc: "HDFC0000067",
    //   name: "HDFC Bank Ltd.",
    // },
    // {
    //   accountNo: "57500000569441",
    //   ifsc: "HDFC0000067",
    //   name: "HDFC Bank Ltd.",
    // },
    {
      accountNo: "138805001474",
      ifsc: "ICIC0001388",
      name: "ICICI Bank Ltd.",
    },
  ];

  const downloadPDF = async (file_identifier) => {
    // setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/arham_web/v1/fetch-pdf-by-identifier/?file_identifier=${encodeURIComponent(
          file_identifier
        )}`,
        {
          method: "GET",
          headers: {
            source: "arham_web",
          },
        }
      );
      console.log("response", response);

      if (!response.ok) {
        throw new Error(`Failed to fetch the file. Status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_identifier);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error.message);
      alert(`Failed to download file: ${error.message}`);
    } finally {
      // setLoading(false);
    }
  };

  return (
    <div>
      <Account />
      <Grid
        container
        sx={{
          backgroundColor: "#2D5A53",
          p: 1,
          px: { xs: 4, md: 20 },
          // mt: 10,
          scrollbarWidth: "none",
          overflowY: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sm={12}>
            <Grid container spacing={4} sx={{ pt: 2 }}>
              <Grid item xs={12} md={3} sm={12} sx={{ textAlign: "left" }}>
                <Box>
                  <img
                    src={ArhamFooter}
                    alt="Logo"
                    style={{
                      width: "200px",
                      fontFamily: "Ubuntu",
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", pt: 2 }}>
                  <img
                    src={Location}
                    style={{ width: "30px", height: "30px" }}
                  />

                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      textAlign: "left",
                      color: "#E0F199",
                      pl: 1,
                      fontSize: "18px",

                      // cursor: "pointer",
                      // display: "flex",
                    }}
                  >
                    Corporate Office
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    pt: 1,
                    pl: 4,

                    fontFamily: "Ubuntu",
                    color: "#FFFFFF",
                  }}
                >
                  U-8, Jolly Plaza, Athwa Gate, Surat - 395 001.
                </Typography>
                <Box sx={{ display: "flex", pt: 2 }}>
                  <img
                    src={Location}
                    style={{ width: "30px", height: "30px" }}
                  />

                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      textAlign: "left",
                      color: "#E0F199",
                      pl: 1,
                      fontSize: "18px",

                      // cursor: "pointer",
                      // display: "flex",
                    }}
                  >
                    Registered Office
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    pt: 1,
                    pl: 4,

                    fontFamily: "Ubuntu",
                    color: "#FFFFFF",
                  }}
                >
                  416-417 A, X-Change Plaza, Dalal Street Commercial Tower,
                  Block 53 E, Gift City, Gandhinagar-382355
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                sx={{
                  fontFamily: "Ubuntu",
                  textAlign: "left",
                  color: "#FFFFFF",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  paddingLeft: "70px !important",

                  // cursor: "pointer",
                  // display: "flex",
                }}
              >
                <Typography style={{ color: "#E0F199", fontFamily: "Ubuntu" }}>
                  Useful Links
                </Typography>

                <Typography>
                  <a
                    href="your-rms-policy-url"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    {/* Stocks */}
                  </a>
                </Typography>
                <Typography>
                  <a
                    href="/ipos"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    IPOs
                  </a>
                </Typography>
                <Typography>
                  <a
                    href="/download"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Downloads
                  </a>
                </Typography>
                <Typography>
                  <a
                    href="/event"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Events
                  </a>
                </Typography>
                <Typography>
                  <a
                    href="/buyback"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Buyback
                  </a>
                </Typography>
                <Typography>
                  <a
                    href="your-cdsl-e-voting-url"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                     News
                  </a>
                </Typography>

                <Typography>
                  <a
                    href="/blog"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Blogs
                  </a>
                </Typography>
                <Typography>
                  <a
                    href="/magazine"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Magazine
                  </a>
                </Typography>
                <Typography>
                  <Link
                    href="https://smartodr.in/login"
                    target="_blank"
                    sx={{
                      color: "White",
                      textDecoration: "none",
                      fontFamily: "Ubuntu",
                      fontSize: "14px",
                    }}
                  >
                    Smart ODR
                  </Link>
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                md={3}
                sx={{
                  fontFamily: "Ubuntu",
                  textAlign: "left",
                  color: "#FFFFFF",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  paddingLeft: "80px !important",

                  // cursor: "pointer",
                  // display: "flex",
                }}
              >
                <Typography>
                  <a
                    href="/faqs"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    FAQs
                  </a>
                </Typography>
                <Typography>
                  <a
                    href="/careers"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Careers
                  </a>
                </Typography>
                <Typography>
                  <a
                    href="your-cdsl-e-voting-url"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Calculators
                  </a>
                </Typography>

                <Typography>
                  <a
                    href="your-investor-protection-url"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Privacy Policy
                  </a>
                </Typography>
                <Typography>
                  <a
                    href="/terms"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Terms of Use
                  </a>
                </Typography>
                <Typography>
                  <a
                    href="/feedback"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Feedback
                  </a>
                </Typography>
                <Typography>
                  <a
                    href="/charts"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Charts
                  </a>
                </Typography>
                <Typography>
                  <Link
                    onClick={handleSebiClick} // Handle click to open the dialog
                    sx={{
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                      cursor: "pointer",
                    }}
                  >
                    Sebi Score
                  </Link>
                </Typography>
                <Typography
                  onClick={() =>
                    downloadPDF("rights_and_obligation_stock_broker_client")
                  }
                  sx={{
                    fontFamily: "Ubuntu",
                    fontWeight: "400",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  Rights and Obligation of stock broker and client
                </Typography>

                {openSebiDialog && (
                  <SebiScore
                    open={openSebiDialog}
                    handleClose={handleCloseDialog}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                {/* <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 400,
                    // pt: 8,
                    fontFamily: "Ubuntu",
                    color: "#E0F199",
                  }}
                >
                  Subscribe to our newsletter
                </Typography>
                <Typography sx={{ pt: 1 }}>
                  <CustomTextField
                    type="email"
                    name="event_type"
                    label="Your Email Address"
                    variant="outlined"
                    fullWidth
                    id="outlined-size-small"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ArrowForwardIcon
                            style={{
                              color: "#396A62",
                              backgroundColor: "#E0F199",
                              borderRadius: "4px",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Typography> */}

                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 400,
                    color: "#E0F199",
                    pt: 1,
                    fontFamily: "Ubuntu",
                  }}
                >
                  Get in touch
                </Typography>
                <Box sx={{ display: "flex", gap: "10px", pt: 1 }}>
                  <a
                    href="https://www.facebook.com/arhamshare"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={Facebook}
                      style={{ width: "25px", height: "25px" }}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/arhamshare/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={instagram}
                      alt="Instagram"
                      style={{ width: "25px", height: "25px" }}
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/@ArhamShare"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={YouTube}
                      style={{ width: "25px", height: "25px" }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/arham-share/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={LinkedinFooter}
                      style={{ width: "25px", height: "25px" }}
                    />
                  </a>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 400,
                      color: "#E0F199",
                      pt: 1,
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Reach us out
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <img
                      src={Email}
                      alt="Email Icon"
                      style={{ marginRight: "10px" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "#FFFFFF",
                        fontFamily: "Ubuntu",
                        cursor: "pointer",
                      }}
                      component="a"
                      href="mailto:contact@arhamshare.com"
                    >
                      contact@arhamshare.com
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" pt={1}>
                    <img
                      src={Phone}
                      alt="Phone Icon"
                      style={{ marginRight: "10px" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "#FFFFFF",
                        fontFamily: "Ubuntu",
                        cursor: "pointer",
                      }}
                    >
                      0261-6794000
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                <Box sx={{ display: "flex", pt: 2 }}>
                  <img
                    src={Location}
                    style={{ width: "30px", height: "30px" }}
                  />

                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      textAlign: "left",
                      color: "#E0F199",
                      pl: 1,
                      fontSize: "18px",

                      // cursor: "pointer",
                      // display: "flex",
                    }}
                  >
                    Communication Address
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    pt: 1,
                    pl: 4,
                    fontFamily: "Ubuntu",
                    color: "#FFFFFF",
                  }}
                >
                  601-606,6th Floor Central Business Hub, Parle Point, Opposite
                  Rajhans Ornate,Surat-395007
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={{ pt: 4 }}>
              <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontFamily: "Ubuntu",
                    color: "#FFFFFF",
                  }}
                >
                  NSE/BSE: INZ000175534, MCX : INZ000085333,CDSL :
                  IN-DP-CDSL-252-2016, MSME Registration Number:
                  UDYAM-GJ-22-0011958
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ pt: 1 }}>
              <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Ubuntu",
                    color: "#FFFFFF",
                  }}
                >
                  <span style={{ color: "#FFFFFF" }}>
                    Details of Compliance Officer: Priyank Mehta |
                  </span>{" "}
                  <StyledLink href="mailto:mehta_priyank@ymail.com">
                    Email: mehta_priyank@ymail.com |
                  </StyledLink>{" "}
                  <span style={{ color: "#FFFFFF" }}>
                    Tel: 0261-67940000 |{" "}
                  </span>{" "}
                  <StyledLink href="https://www.sebi.gov.in/">SEBI</StyledLink>{" "}
                  |<StyledLink href="https://www.nseindia.com">NSE</StyledLink>{" "}
                  |<StyledLink href="https://www.bseindia.com">BSE</StyledLink>{" "}
                  |<StyledLink href="https://www.nsdl.co.in">NSDL</StyledLink> |
                  <StyledLink href="https://www.cdslindia.com">CDSL</StyledLink>{" "}
                  |<StyledLink href="https://www.mcxindia.com">MCX</StyledLink>{" "}
                  |<StyledLink href="https://www.ncdex.com">NCDEX</StyledLink> |
                  <StyledLink href="https://www.rbi.org.in">RBL</StyledLink> |
                  <StyledLink href="https://www.irda.gov.in">IRDA</StyledLink> |
                  <StyledLink href="https://www.amfiindia.com">AMFI</StyledLink>{" "}
                  <Typography>
                    <a
                      // href="https://www.amfiindia.com"
                      style={{
                        color: "#2D5A53",
                      }}
                    >
                      {/* AMFI */}
                    </a>
                  </Typography>
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={{ pt: 4 }}>
              <Grid item md={12} xs={12}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    textAlign: "left",
                    color: "#E0F199",
                    // pl: 1,
                    fontSize: "18px",
                  }}
                >
                  Attention Investors
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    textAlign: "left",
                    color: "#FFFF",
                    pt: 1,
                    fontSize: "14px",
                  }}
                >
                  "Prevent Unauthorised Transactions in your account Update your
                  mobile numbers/email IDs with your stock brokers. Receive
                  information of your transactions directly from Exchange on
                  your mobile/email at the end of the day Issued in the interest
                  of investors"
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    textAlign: "left",
                    color: "#FFFF",
                    pt: 1,
                    fontSize: "14px",
                  }}
                >
                  "KYC is one time exercise while dealing in securities markets
                  - once KYC is done through a SEBI registered intermediary
                  (broker, DP, Mutual Fund etc.), you need not undergo the same
                  process again when you approach another intermediary."
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    textAlign: "left",
                    color: "#FFFF",
                    pt: 1,
                    fontSize: "14px",
                  }}
                >
                  "Prevent Unauthorized Transactions in your demat account
                  Update your Mobile Number with your Depository Participant.
                  Receive alerts on your Registered Mobile for all debit and
                  other important transactions in your demat account directly
                  from CDSL on the same day issued in the interest of
                  investors."
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    textAlign: "left",
                    color: "#FFFF",
                    pt: 1,
                    fontSize: "14px",
                  }}
                >
                  "No need to issue cheques by investors while subscribing to
                  IPO. Just write the bank account number and sign in the
                  application form to authorise your bank to make payment in
                  case of allotment. No worries for refund as the money remains
                  in investor's account."
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    textAlign: "left",
                    color: "#FFFF",
                    pt: 1,
                    fontSize: "14px",
                  }}
                >
                  "As required under circular # SEBI/MRD/SE/Cir-42/2003 dated
                  November 19, 2003 issued by the Securities and Exchange Board
                  of India; we hereby disclose that in addition to Client base
                  Business, we are also doing Proprietary Trading"
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      textAlign: "left",
                      color: "#FFFF",
                      pt: 1,
                      fontSize: "14px",
                      display: "inline",
                    }}
                  >
                    Client Registration Documents in Vernacular Languages{" "}
                  </Typography>
                  <Typography
                    onClick={() =>
                      downloadPDF("Client_Registration_Documents.pdf")
                    }
                    sx={{
                      color: "#2cb7ff",
                      textDecoration: "underline",
                      fontWeight: "400",
                      pl: 1,
                      cursor: "pointer",
                      "&:hover": {
                        color: "#2cb7ff",
                      },
                    }}
                  >
                    Click here
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center">
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      textAlign: "left",
                      color: "#FFFF",
                      pt: 1,
                      fontSize: "14px",
                      display: "inline",
                    }}
                  >
                    Commodity Client Registration Documents in Vernacular
                    Languages{" "}
                  </Typography>
                  <Typography
                    onClick={() =>
                      downloadPDF("Client_Registration_Documents.pdf")
                    }
                    sx={{
                      color: "#2cb7ff",
                      textDecoration: "underline",
                      fontWeight: "400",
                      pl: 1,
                      cursor: "pointer",
                      "&:hover": {
                        color: "#2cb7ff",
                      },
                    }}
                  >
                    Click here
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      textAlign: "left",
                      color: "#FFFF",
                      pt: 1,
                      fontSize: "14px",
                      display: "inline",
                    }}
                  >
                    Account Opening Procedure{" "}
                  </Typography>
                  <Typography
                    onClick={() =>
                      downloadPDF("Client_Registration_Documents.pdf")
                    }
                    sx={{
                      color: "#2cb7ff",
                      textDecoration: "underline",
                      fontWeight: "400",
                      pl: 1,
                      cursor: "pointer",
                      "&:hover": {
                        color: "#2cb7ff",
                      },
                    }}
                  >
                    Click here
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      textAlign: "left",
                      color: "#FFFF",
                      pt: 1,
                      fontSize: "14px",
                      display: "inline",
                    }}
                  >
                    List of AP
                  </Typography>
                  <Typography
                    onClick={() => downloadPDF("List of AP")}
                    sx={{
                      color: "#2cb7ff",
                      textDecoration: "underline",
                      fontWeight: "400",
                      pl: 1,
                      cursor: "pointer",
                      "&:hover": {
                        color: "#2cb7ff",
                      },
                    }}
                  >
                    Click here
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      textAlign: "left",
                      color: "#FFFF",
                      pt: 1,
                      fontSize: "14px",
                      display: "inline",
                    }}
                  >
                    Ekyc flow char
                  </Typography>
                  <Typography
                    onClick={() => downloadPDF("Ekyc flow chart")}
                    sx={{
                      color: "#2cb7ff",
                      textDecoration: "underline",
                      fontWeight: "400",
                      pl: 1,
                      cursor: "pointer",
                      "&:hover": {
                        color: "#2cb7ff",
                      },
                    }}
                  >
                    Click here
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    textAlign: "left",
                    color: "#E0F199",
                    // pl: 1,
                    py: 2,
                    fontSize: "18px",
                  }}
                >
                  Key Managerial Personnels
                </Typography>
                <Box
                  sx={{
                    overflowX: "auto",
                  }}
                >
                  <Table
                    sx={{
                      borderCollapse: "separate",
                      borderSpacing: "0",
                      border: "1px solid #396A62",
                      borderRadius: "10px",
                      minWidth: "650px",
                      overflow: "hidden",
                    }}
                  >
                    <TableHead style={{ backgroundColor: "#396A62" }}>
                      <TableRow>
                        <TableCell
                          style={{ color: "white", textAlign: "left" }}
                        >
                          Sr. No
                        </TableCell>
                        <TableCell
                          style={{ color: "white", textAlign: "left" }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          style={{ color: "white", textAlign: "left" }}
                        >
                          Designation
                        </TableCell>
                        <TableCell
                          style={{ color: "white", textAlign: "left" }}
                        >
                          MobileNo
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ color: "white", textAlign: "left" }}>
                            {index + 1} {/* Auto-incrementing Sr. No */}
                          </TableCell>
                          <TableCell sx={{ color: "white", textAlign: "left" }}>
                            {row.companyName}
                          </TableCell>
                          <TableCell sx={{ color: "white", textAlign: "left" }}>
                            {row.Designation}
                          </TableCell>
                          <TableCell sx={{ color: "white", textAlign: "left" }}>
                            {row.MobileNo}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    textAlign: "left",
                    color: "#E0F199",
                    py: 2,
                    fontSize: "18px",
                  }}
                >
                  Bank Details
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    textAlign: "left",
                    color: "#FFFF",
                    pt: 1,
                    fontSize: "14px",
                  }}
                >
                  “Investors are requested to note that Stock broker Arham Share
                  Private Ltd. is permitted to receive/pay money from/to
                  investor through designated bank accounts only named as client
                  bank accounts. Stock broker Arham Share Private Ltd. is also
                  required to disclose these client bank accounts to Stock
                  Exchange. Hence, you are requested to use following client
                  bank accounts only for the purpose of dealings in your trading
                  account with us. The details of these client bank accounts are
                  also displayed by Stock Exchanges on their website under
                  “Know/ Locate your Stock Broker”.
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    textAlign: "left",
                    color: "#E0F199",
                    // pl: 1,
                    py: 2,
                    fontSize: "18px",
                  }}
                >
                  Arham Share Pvt. Ltd.- Client Bank Account List
                </Typography>
                <Box
                  sx={{
                    overflowX: "auto",
                  }}
                >
                  <Table
                    sx={{
                      borderCollapse: "separate",
                      borderSpacing: "0",
                      border: "1px solid #396A62",
                      borderRadius: "10px",
                      minWidth: "650px",
                      overflow: "hidden",
                    }}
                  >
                    <TableHead style={{ backgroundColor: "#396A62" }}>
                      <TableRow>
                        <TableCell
                          style={{ color: "white", textAlign: "left" }}
                        >
                          Sr
                        </TableCell>
                        <TableCell
                          style={{ color: "white", textAlign: "left" }}
                        >
                          Bank Account No.
                        </TableCell>
                        <TableCell
                          style={{ color: "white", textAlign: "left" }}
                        >
                          IFSC
                        </TableCell>
                        <TableCell
                          style={{ color: "white", textAlign: "left" }}
                        >
                          Name of Bank
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bankData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            sx={{
                              color: "white",
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                          >
                            {index + 1} {/* Auto-incrementing Sr. No */}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "white",
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                          >
                            {row.accountNo}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "white",
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                          >
                            {row.ifsc}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "white",
                              textAlign: "left",
                              border: "1px solid gray",
                            }}
                          >
                            {row.name}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    textAlign: "left",
                    color: "#E0F199",
                    // pl: 1,
                    py: 2,
                    fontSize: "18px",
                  }}
                >
                  Arham Commodity Brokers Pvt. Ltd.- Client Bank Account List
                </Typography>
                <Box
                  sx={{
                    overflowX: "auto",
                  }}
                >
                  <Table
                    sx={{
                      borderCollapse: "separate",
                      borderSpacing: "0",
                      border: "1px solid #396A62",
                      borderRadius: "10px",
                      minWidth: "650px",
                      overflow: "hidden",
                    }}
                  >
                    <TableHead style={{ backgroundColor: "#396A62" }}>
                      <TableRow>
                        <TableCell
                          style={{ color: "white", textAlign: "left" }}
                        >
                          Sr
                        </TableCell>
                        <TableCell
                          style={{ color: "white", textAlign: "left" }}
                        >
                          Bank Account No.
                        </TableCell>
                        <TableCell
                          style={{ color: "white", textAlign: "left" }}
                        >
                          IFSC
                        </TableCell>
                        <TableCell
                          style={{ color: "white", textAlign: "left" }}
                        >
                          Name of Bank
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "white",
                            textAlign: "left",
                            border: "1px solid gray",
                          }}
                        >
                          1
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            textAlign: "left",
                            border: "1px solid gray",
                          }}
                        >
                          00670340003257
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            textAlign: "left",
                            border: "1px solid gray",
                          }}
                        >
                          HDFC0000067
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            textAlign: "left",
                            border: "1px solid gray", // Border color set to gray
                          }}
                        >
                          HDFC Bank Ltd
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Grid>

            <Grid>
              <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
                {/* <hr /> */}
                <p style={{ fontFamily: "Ubuntu", color: "#FFFFFF" }}>
                  Copyright © 2024 Arham Share Pvt. Ltd. All Rights Reserved
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;
