import { useLocation } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import Header from "../Component/HomePage/Header";
import Fotter from "../Component/Footer";

function EventDetail() {
  const location = useLocation();
  const event = location.state?.event;

  return (
    <>
      <Header />

      <Grid
        container
        sx={{
          background:
            "linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 40%)",
            px: { xs: 4, md: 4, lg: 18 },
          py: 4,
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{ margin: "auto 0", textAlign: "center" }}
        >
          <Typography
            sx={{
              fontSize: "55px",
              fontFamily: "Ubuntu",
              fontWeight: 500,
            }}
          >
            All Events
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} sx={{ pt: 6 }}>
          <Typography
            sx={{
              fontSize: "32px",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              textAlign: "left",
              color: "#396A62",
            }}
          >
            {event.event_header}
          </Typography>
        </Grid>
        {/* Check if event and images are available */}
        {event && event.images && event.images.length > 0 ? (
          <Grid container spacing={4} pt={4}>
            {event.images.map((image, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                {/* Image container with consistent size */}
                <Box
                  sx={{
                    borderRadius: "15px",
                    overflow: "hidden",
                    width: "100%", // Ensure the container takes full width of its grid item
                    height: "300px", // Set a consistent height for all image boxes
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <img
                    src={`data:image/png;base64,${image.file_data}`}
                    alt={`Event Image ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover", // Ensures the image covers the box without distortion
                      borderRadius: "15px",
                    }}
                  />
                </Box>

                {/* Event Details */}
                <Box sx={{ mt: 2, pl: 2 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "left",
                      fontFamily: "Ubuntu",
                      color: "#396A62",
                    }}
                  >
                    {event.event_duration}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: 18,
                      textAlign: "left",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    {event.event_header}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>No images available</Typography>
        )}
      </Grid>
      <Fotter />
    </>
  );
}

export default EventDetail;
