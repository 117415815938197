import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Card,
  Breadcrumbs,
  Link,
  Box,
  styled,
  TextField,
  InputAdornment,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import Header from "../Component/HomePage/Header";
import Footer from "../Component/Footer";

const CustomTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    color: "black !important",
    backgroundColor: "white",
    fontFamily: "Ubuntu",
    borderRadius: "8px",
  },
}));

function IpoDetailsPage() {
  const location = useLocation();
  const ipo = location.state?.ipo;
  const status = location.state?.status;

  const navigate = useNavigate();

  if (!ipo) {
    return <Typography variant="h6">IPO details not found!</Typography>;
  }
  const handleOpenDematClick = () => {
    window.location.href = "https://ekyc.arhamshare.com/";
  };

  const handleBreadcrumbClick = (event, path) => {
    event.preventDefault();
    navigate(path);
  };
  const ipoTimelineSteps = [
    { label: "Bidding Starts", date: ipo.biddingstartdate },
    { label: "Bidding Ends", date: ipo.biddingenddate },
    { label: "Allotment finalisation", date: ipo.listingdate },
    { label: "Refund initiation", date: ipo.listingdate },
    { label: "Demat transfer", date: ipo.listingdate },
    { label: "Listing", date: ipo.listingdate },
  ];
  const currentDate = new Date();

  return (
    <>
      <Header />
      <Grid
        container
        sx={{
          background:
            "linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 100%)",
            px: { xs: 4, md: 4, lg: 18 },
        }}
      >
        <Grid container spacing={3} py={4}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontFamily: "Ubuntu",
                textAlign: "center",
                fontSize: { xs: "25px", md: "38px" },
              }}
            >
              {ipo.name}
            </Typography>

            {/* Centering the Breadcrumbs */}
            <Box sx={{ display: "flex", justifyContent: "center", pt: 1 }}>
              <Breadcrumbs aria-label="breadcrumb" separator=">">
                <Link
                  color="inherit"
                  href="/"
                  onClick={(event) => handleBreadcrumbClick(event, "/")}
                  sx={{
                    cursor: "pointer",
                    textDecoration: "none",
                    fontFamily: "Ubuntu",
                    fontWeight: 500,
                    fontSize: "22px",
                    color: "#3D3D3D",
                    textAlign: "center",
                  }}
                >
                  Home
                </Link>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontWeight: 400,
                    fontSize: "22px",
                    color: "#3D3D3D",
                    textAlign: "center",
                  }}
                >
                  Blog
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontWeight: 400,
                    fontSize: "22px",
                    color: "#3D3D3D",
                    textAlign: "center",
                  }}
                >
                  {ipo.name}
                </Typography>
              </Breadcrumbs>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item md={8}>
            <Grid item md={12}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontFamily: "Ubuntu",
                  textAlign: "left",
                  fontSize: "20px",
                }}
              >
                {ipo.name}
              </Typography>
            </Grid>

            <Grid container>
              <Grid item md={6}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    textAlign: "left",
                    fontSize: "18px",
                    pt: 1,
                  }}
                >
                  {" "}
                  Status:{" "}
                  <span
                    style={{
                      fontFamily: "Ubuntu",
                      textAlign: "left",
                      fontSize: "18px",
                      pt: 1,
                      color: "#396A62",
                    }}
                  >
                    {status}
                  </span>
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Ubuntu",
                    textAlign: "right",
                    fontSize: "22px",
                    color: "#396A62",
                    pt: 1,
                  }}
                >
                  ₹{ipo.minbidquantity * ipo.cutoffprice}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    textAlign: "right",
                    fontSize: "15px",
                    color: "#3D3D3D",
                    pt: 1,
                  }}
                >
                  {ipo.minbidquantity} Shares Minimum Investment
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    p: 3,
                    borderRadius: 4,
                    backgroundColor: "white",
                    boxShadow: "none",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Ubuntu",
                      mb: 2,
                      textAlign: "left",
                      color: "#396A62",
                      fontWeight: 500,
                    }}
                  >
                    IPO Listing Details
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        sx={{ fontFamily: "Ubuntu", color: "#3D3D3D" }}
                      >
                        Listing Date
                      </Typography>
                      <Typography
                        sx={{ fontFamily: "Ubuntu", fontWeight: "bold" }}
                      >
                        24 Sep 2024
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        sx={{ fontFamily: "Ubuntu", color: "#3D3D3D" }}
                      >
                        Listing Price
                      </Typography>
                      <Typography
                        sx={{ fontFamily: "Ubuntu", fontWeight: "bold" }}
                      >
                        ₹175.90
                      </Typography>
                    </Grid>
                   
                    <Grid item xs={3}>
                      <Typography
                        sx={{ fontFamily: "Ubuntu", color: "#3D3D3D" }}
                      >
                        Last Traded Price
                      </Typography>
                      <Typography
                        sx={{ fontFamily: "Ubuntu", fontWeight: "bold" }}
                      >
                        ₹156.99
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Ubuntu",
                      pt: 4,
                      textAlign: "left",
                      color: "#396A62",
                      fontWeight: 500,
                    }}
                  >
                    IPO Details
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        sx={{ fontFamily: "Ubuntu", color: "#3D3D3D" }}
                      >
                        Open Date
                      </Typography>
                      <Typography
                        sx={{ fontFamily: "Ubuntu", fontWeight: "bold" }}
                      >
                        {ipo.biddingstartdate}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        sx={{ fontFamily: "Ubuntu", color: "#3D3D3D" }}
                      >
                        Close Date
                      </Typography>
                      <Typography
                        sx={{ fontFamily: "Ubuntu", fontWeight: "bold" }}
                      >
                        {ipo.biddingenddate}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        sx={{ fontFamily: "Ubuntu", color: "#3D3D3D" }}
                      >
                        Price Range
                      </Typography>
                      <Typography
                        sx={{ fontFamily: "Ubuntu", fontWeight: "bold" }}
                      >
                        ₹{ipo.minprice} - ₹{ipo.cutoffprice}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        sx={{ fontFamily: "Ubuntu", color: "#3D3D3D" }}
                      >
                        Issue Size
                      </Typography>
                      <Typography
                        sx={{ fontFamily: "Ubuntu", fontWeight: "bold" }}
                      >
                        {ipo.issuesize}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={3}>
            <Card sx={{ backgroundColor: "#396A62", borderRadius: 4, p: 4 }}>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  textAlign: "center",
                  fontSize: "20px",
                  color: "white ",
                }}
              >
                Interested in investing in an IPO?
              </Typography>
              {/* <Box sx={{ pt: 1 }}>
                <CustomTextField
                  type="text" // Change to text to limit characters
                  size="small"
                  placeholder="Enter Mobile Number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ color: "black !important", fontFamily: "Ubuntu" }}
                      >
                        +91
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: 10, // Limit to 10 digits
                    inputMode: "numeric", // Ensure a numeric keyboard on mobile devices
                    pattern: "[0-9]*", // Allow only numbers
                  }}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, ""); // Only allow numbers
                    if (value.length <= 10) {
                      e.target.value = value; // Set the value to max 10 digits
                    }
                  }}
                />
              </Box> */}
              <Box sx={{ py: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#396A62",
                    "&:hover": {
                      backgroundColor: "#0B2A29",
                    },
                    fontFamily: "Ubuntu",
                    border: "1px #0B2A29",
                    borderRadius: "8px",
                    height: "38px",
                    textTransform: "none",
                    color: "white",
                    border: "1px solid white",
                  }}
                  className="header-btn"
                  type="submit"
                  onClick={handleOpenDematClick}
                >
                  <span style={{ fontSize: "13px" }}>Open Demat Account</span>
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Ubuntu",
                mb: 2,
                textAlign: "left",
                color: "#396A62",
                pl: 1,
                fontWeight: 500,
              }}
            >
              IPO Timeline
            </Typography>
            <Card
              sx={{
                p: 3,
                borderRadius: 4,
                backgroundColor: "white",
                boxShadow: "none",
                border: "1px solid #CCD8D6",
                width: "80%",
                overflow: "hidden",
              }}
            >
              <div style={{ overflowX: "auto" }}>
                <Stepper alternativeLabel activeStep={0} sx={{ pt: 3 }}>
                  {ipoTimelineSteps.map((step, index) => {
                    const stepDate = new Date(step.date);
                    const isPast = stepDate < currentDate;
                    return (
                      <Step key={step.label}>
                        <StepLabel
                          sx={{
                            "& .MuiStepLabel-label": {
                              backgroundColor: isPast
                                ? "#396A62"
                                : "transparent",
                              color: isPast ? "white" : "inherit",
                              borderRadius: "4px",
                              padding: "4px 8px",
                              fontFamily: "Ubuntu",
                            },
                          }}
                        >
                          {step.label} <br />
                          {step.date}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default IpoDetailsPage;
