import { Box, Button, Grid, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React, { useEffect, useState } from "react";

function PartnerArham() {
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 400) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Grid
        container
        sx={{
          px: { xs: 4, md: 4, lg: 14 },
          background: "#E7F2F0",
          mt: 8,
          py: 4,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontSize: { md: "40px", xs: "25px" },
                    textAlign: "center",
                    fontFamily: "Ubuntu",
                    fontWeight: 500,
                    pt: 4,
                  }}
                >
                  Partner with{" "}
                  <span style={{ color: "#396A62" }}>Arham Shares</span>
                </Typography>
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    textAlign: "center",
                    fontFamily: "Ubuntu",
                    fontWeight: 400,
                    pt: 2,
                  }}
                >
                  Become an Arham Shares partner and unlock a world of
                  investment opportunities. Our expert guidance and personalized
                  support will help you build a strong financial foundation.
                </Typography>
              </Grid>

              <Grid item xs={12} md={12} pt={3}>
                {showScrollToTop && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={scrollToTop}
                    endIcon={<ArrowForwardIcon sx={{ fontSize: "30px" }} />}
                    sx={{
                      backgroundColor: "#396A62",
                      "&:hover": {
                        backgroundColor: "#0B2A29",
                      },
                      textTransform: "none",
                      fontFamily: "Ubuntu",
                      border: "1px #0B2A29",
                      borderRadius: "10px",
                      fontSize: "large",
                      textTransform: "none",
                      //   width: "100%",
                      // height: "50px",
                    }}
                    //   className="header-btn"
                  >
                    <span style={{ fontSize: "15px" }}>
                      Start Your Journey Today
                    </span>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          px: { xs: 4, md: 4, lg: 18 },
        }}
      >
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography
              sx={{
                fontSize: {md:"40px" ,xs:"28px"},
                textAlign: "center",
                fontFamily: "Ubuntu",
                fontWeight: 500,
                pt: 8,
              }}
            >
              Partners <span style={{ color: "#396A62" }}>Success Story</span>
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container sx={{ position: "relative", height: "100%" }}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "5px",
                    bottom: "0",
                    right: "0",
                    borderRight: { xs: "none", md: "2px solid #2D5A53" },
                  }}
                ></Box>

                <Grid container sx={{ pt: 4 }}>
                  <Grid item xs={12} md={11}>
                    <Typography
                      sx={{
                        fontSize: {md:"22px" ,xs:"22px"},
                        textAlign: "left",
                        fontFamily: "Ubuntu",
                        fontWeight: 500,
                        // pt: 2,
                        pb: 2,
                      }}
                    >
                      From 10 Clients to 3,000 - The Inspiring Journey of Our
                      Top Partner, MB Branch
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={11}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        textAlign: "left",
                        fontFamily: "Ubuntu",
                        // pt: 2,
                      }}
                    >
                      This is the amazing tale of MB Branch, our longest-serving
                      and most prominent partner.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        textAlign: "left",
                        fontFamily: "Ubuntu",
                        pt: 1,
                      }}
                    >
                      Their adventure began in 2017 with a bare minimum staff.
                      MB Branch began with just 10 consumers and has steadily
                      grown to a clientele base of over 3,000. This tremendous
                      success demonstrates their constant devotion, great
                      service, and the strength of our joint collaboration.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        textAlign: "left",
                        fontFamily: "Ubuntu",
                        pt: 1,
                      }}
                    >
                      We are quite fortunate to have had MB Branch on board for
                      the last eight years. Their passion and achievement
                      inspire all of our APs.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container sx={{ pt: 4 }}>
                <Grid item xs={12} md={11}>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      textAlign: "left",
                      fontFamily: "Ubuntu",
                      fontWeight: 500,
                      // pt: 2,
                      pb: 2,
                    }}
                  >
                    Building Trust Across Borders, A Kerala Success Story
                  </Typography>
                </Grid>
                <Grid item xs={12} md={11}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      textAlign: "left",
                      fontFamily: "Ubuntu",
                      // pt: 2,
                    }}
                  >
                    Our collaboration with Sateeshan from Kerala demonstrates
                    the ability of overcoming initial misgivings. Despite his
                    initial reservations about our headquarters being in Surat,
                    Gujarat, Sateeshan put his confidence in Arham Share and
                    opted to work with us.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      textAlign: "left",
                      fontFamily: "Ubuntu",
                      pt: 1,
                    }}
                  >
                    This choice has been tremendously successful. For the
                    previous three years, Sateeshan has developed solid client
                    connections and produced substantial income. This success
                    story highlights the importance of fostering trust and
                    collaboration across regional borders.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default PartnerArham;
