import {
  Box,
  Button,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  InputAdornment,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import React from "react";
import Header from "../Component/HomePage/Header";
import Career from "../assets/images/Career/Career.png";
import Innovative from "../assets/images/Career/Innovative.png";
import Rapid from "../assets/images/Career/Rapid.png";
import Reward from "../assets/images/Career/Reward.png";
import Supportive from "../assets/images/Career/Supportive.png";
import CityIcon from "../assets/images/Career/CityIcon.png";
import Dep from "../assets/images/Career/Dep.png";
import Fotter from "../Component/Footer";
import Desgination from "../assets/images/Career/Dis.png";

function index() {
  return (
    <>
      <Header />
      <Grid
        container
        sx={{
          background:
            " linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 100%)",
          px: { xs: 4, md: 4, lg: 18 },
        }}
      >
        <Grid container pt={6} spacing={4}>
          <Grid item xs={12} md={6} sx={{ margin: "auto 0" }}>
            <Typography
              sx={{
                fontSize: { md: "47px", xs: "35px" },
                fontFamily: "Ubuntu",
                fontWeight: 500,
                // pt: 4,
                textAlign: "left",
              }}
            >
              Careers at Arham Share
            </Typography>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                pt: 2,
                textAlign: "left",
              }}
            >
              Involve yourself into the exciting world of finance, where working
              in the stock market offers unmatched opportunities for growth,
              dynamic decision-making, and the chance to shape financial
              futures.
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <img src={Career} style={{ width: "80%" }} />
          </Grid>
        </Grid>
        <Grid container pt={6}>
          <Grid item md={12} sm={12}>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                color: "#396A62",
                fontSize: "24px",
                fontWeight: 500,
              }}
            >
              Benefits of Working at Arham Share
            </Typography>
          </Grid>
        </Grid>
        <Grid container pt={6} spacing={2}>
          {[
            { imgSrc: Rapid, text: "Rapid Career Growth" },
            { imgSrc: Supportive, text: "Supportive Community" },
            { imgSrc: Innovative, text: "Innovative Work Culture" },
            { imgSrc: Reward, text: "Recognition & Rewards" },
          ].map((item, index) => (
            <Grid item key={index} md={3} sm={6} xs={12}>
              <Box
                sx={{
                  backgroundColor: "#EFF6F5",
                  py: 2,
                  border: "1px solid #D6D6D6",
                  borderRadius: 2,
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: { xs: "auto", sm: "70%" },
                }}
              >
                <img
                  src={item.imgSrc}
                  alt={item.text}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    color: "#3D3D3D",
                    fontSize: "20px",
                    fontWeight: 500,
                    mt: 2,
                  }}
                >
                  {item.text}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid container pt={6} spacing={4} justifyContent="center">
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: "#F2F5E5",
                padding: 4,
                borderRadius: 2,
                textAlign: "center",
                border: "1px solid #D6D6D6",
              }}
            >
              <Typography
                sx={{
                  fontSize: "30px",
                  fontFamily: "Ubuntu",
                  fontWeight: 500,
                  color: "#697523",

                  // pt: 4,
                  textAlign: "center",
                }}
              >
                {" "}
                Apply for jobs
              </Typography>
              <Grid container spacing={2} justifyContent="center" pt={4}>
                {/* Select City */}
                <Grid item xs={12} sm={4} md={4}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="designation-select-label"
                      sx={{
                        fontFamily: "Ubuntu",
                      }}
                    >
                      Select City
                    </InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      input={
                        <OutlinedInput
                          label="Select City"
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={CityIcon} style={{ width: "20px" }} />
                            </InputAdornment>
                          }
                        />
                      }
                      sx={{
                        color: "#396A62",
                        fontFamily: "Ubuntu",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "4px",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#396A62",
                        },
                        "& .MuiSelect-icon": {
                          color: "#697523",
                        },
                      }}
                    >
                      {/* Replace the below MenuItem components with dynamic options */}
                      {/* <MenuItem value="city1">City 1</MenuItem>
                      <MenuItem value="city2">City 2</MenuItem>
                      <MenuItem value="city3">City 3</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Select Designation */}
                <Grid item xs={12} sm={4} md={4}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="designation-select-label"
                      sx={{
                        fontFamily: "Ubuntu",
                      }}
                    >
                      Select Designation
                    </InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      input={
                        <OutlinedInput
                          label="Select Designation"
                          startAdornment={
                            <InputAdornment position="start">
                              <img
                                src={Desgination}
                                style={{ width: "20px" }}
                              />
                            </InputAdornment>
                          }
                        />
                      }
                      sx={{
                        color: "#396A62",
                        fontFamily: "Ubuntu",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "4px",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#396A62",
                        },
                        "& .MuiSelect-icon": {
                          color: "#697523",
                        },
                      }}
                    >
                      {/* <MenuItem value="designation1">Designation 1</MenuItem>
                      <MenuItem value="designation2">Designation 2</MenuItem>
                      <MenuItem value="designation3">Designation 3</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Select Department */}
                <Grid item xs={12} sm={4} md={4}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="designation-select-label"
                      sx={{
                        fontFamily: "Ubuntu",
                      }}
                    >
                      Select Department
                    </InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      input={
                        <OutlinedInput
                          label="Select Department"
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={Dep} style={{ width: "20px" }} />
                            </InputAdornment>
                          }
                        />
                      }
                      sx={{
                        color: "#396A62",
                        fontFamily: "Ubuntu",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "4px",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#396A62",
                        },
                        "& .MuiSelect-icon": {
                          color: "#697523",
                        },
                      }}
                    >
                      {/* Replace the below MenuItem components with dynamic options */}
                      {/* <MenuItem value="department1">Department 1</MenuItem>
                      <MenuItem value="department2">Department 2</MenuItem>
                      <MenuItem value="department3">Department 3</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Search Button */}
                <Grid item xs={12} sm={12} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      backgroundColor: "#697523",
                      color: "#ffffff",
                      height: "100%",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#697523",
                      },
                    }}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid container>
          <Typography
            sx={{
              fontSize: "18px",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              color: "#3D3D3D",
              pt: 2,
              textAlign: "center",
            }}
          >
            Interested in joining us? Forward your CV to{" "}
            <a
              href="mailto:hr@arhamshare.com"
              style={{
                color: "#396A62",
                textDecoration: "none",
                fontWeight: "bold",
              }}
            >
              hr@arhamshare.com
            </a>{" "}
            and let's grow together!
          </Typography>
        </Grid>
      </Grid>
      <Fotter />
    </>
  );
}

export default index;
