import React, { useState } from "react";
import Header from "../Component/HomePage/Header";
import { Box, Grid, Typography, TextField, Button, Card, Alert } from "@mui/material";
import InverstmentJourney from "./inverstmentJourney";
import PartnerArham from "./partnerArham";
import "../style/style.css";
import Review from "./Review";
import Fotter from "../Component/Footer";
import { useFormik } from "formik";
import * as Yup from "yup";

const Index = () => {
  const [submitStatus, setSubmitStatus] = useState("");

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
    address: Yup.string().required("Address is required"),
    briefDescription: Yup.string().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      briefDescription: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/arham_web/v1/contactus/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              name: values.name,
              email: values.email,
              phone_no: values.phoneNumber,
              address: values.address,
              desc: values.briefDescription,
            }),
          }
        );

        const result = await response.json();

        if (response.ok) {
          setSubmitStatus("success");
          formik.resetForm();
        } else {
          setSubmitStatus("fail");
          console.error("Failed to submit form:", result.message);
        }
      } catch (error) {
        setSubmitStatus("fail");
        console.error("Error submitting form:", error);
      }
    },
  });

  return (
    <>
      <Header />

      <Grid
        container
        sx={{
          background:
            "linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 100%)",
            px: { xs: 4, md: 4, lg: 20 },

          
        }}
      >
        <Grid container spacing={2} >
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Box sx={{ pt: 8 }}>
                <Typography
                  sx={{
                    fontSize: { md: "44px", xs: "25px" },
                    fontWeight: 600,
                    textAlign: "left",
                    color: "#396A62",
                    fontFamily: "Ubuntu",
                    pt: { md: 4 },
                  }}
                >
                  Partner with Arham Share,
                  <br />
                  <span style={{ color: "black" }}>Earn with Arham Share.</span>
                </Typography>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: { md: "25px", xs: "18px" },
                      fontFamily: "Ubuntu",
                      fontWeight: 500,
                      pt: 2,
                    }}
                  >
                    Partnering with Arham Share provides distinct benefits.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: { md: "16px", xs: "13px" },
                      fontFamily: "Ubuntu",
                      pt: 1,
                    }}
                  >
                    Take the first step toward a rewarding collaboration by
                    entering your information and get yourself registered as an
                    Arham Share partner now.
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid
        item
        xs={12}
        md={6}
        lg={5}
        sx={{
          paddingLeft: { md: "100px !important" },
          paddingTop: { md: "80px !important" },
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 2,
            backgroundColor: "white",
            border: "1px solid white",
            borderRadius: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "22px",
              textAlign: "center",
              color: "#396A62",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              pb: 1,
            }}
          >
            Get in touch
          </Typography>

          {/* Show success or failure message */}
          {submitStatus === "success" && (
            <Alert severity="success" sx={{ width: "100%", mb: 2 }}>
              Form submitted successfully!
            </Alert>
          )}
          {submitStatus === "fail" && (
            <Alert severity="error" sx={{ width: "100%", mb: 2 }}>
              Failed to submit the form. Please try again later.
            </Alert>
          )}

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  InputLabelProps={{
                    style: {
                      fontFamily: "Ubuntu",
                      color: "black",
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      fontFamily: "Ubuntu",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputLabelProps={{
                    style: {
                      fontFamily: "Ubuntu",
                      color: "black",
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      fontFamily: "Ubuntu",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="phoneNumber"
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  InputLabelProps={{
                    style: {
                      fontFamily: "Ubuntu",
                      color: "black",
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      fontFamily: "Ubuntu",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="address"
                  label="Address"
                  variant="outlined"
                  fullWidth
                  size="small"
                  multiline
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                  InputLabelProps={{
                    style: {
                      fontFamily: "Ubuntu",
                      color: "black",
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      fontFamily: "Ubuntu",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="briefDescription"
                  label="Brief Description"
                  variant="outlined"
                  fullWidth
                  size="small"
                  multiline
                  rows={3}
                  value={formik.values.briefDescription}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.briefDescription &&
                    Boolean(formik.errors.briefDescription)
                  }
                  helperText={
                    formik.touched.briefDescription &&
                    formik.errors.briefDescription
                  }
                  InputLabelProps={{
                    style: {
                      fontFamily: "Ubuntu",
                      color: "black",
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      fontFamily: "Ubuntu",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: "#396A62",
                    "&:hover": {
                      backgroundColor: "#0B2A29",
                    },
                    textTransform: "none",
                    fontFamily: "Ubuntu",
                    borderRadius: "8px",
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
        </Grid>
      </Grid>

      <InverstmentJourney />
      <PartnerArham />
      <Review />
      <Fotter />
    </>
  );
};

export default Index;
