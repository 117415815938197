import React, { useState, useEffect } from "react";
import {
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Grid,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import PerksCards from "../Perks";
import Modal from "../Modal/index";
import Director from "../Director/index";
import Department from "../Department/index";
import Footer from "../Footer/index";
import ArhamFeature from "../ArhamFeature/index";
import ArhamLogo from "../../assets/images/logo/logo.png";
import Card1 from "../../assets/images/ArhamImg/Card1.png";
import Card2 from "../../assets/images/ArhamImg/Card2.png";
import Card3 from "../../assets/images/ArhamImg/Card3.png";
import Arham from "../../assets/images/ArhamImg/Arham.png";
import NavDrawer from "../Drawer/NavDrawer";
import "../../style/style.css";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Journey from "../Journey";
import QRCodeDisplay from "./QRCodeDisplay ";
import { Link } from "react-router-dom";
import Header from "./Header";

const theme = createTheme();

export default function Index() {
  const { ref: textRef, inView: textInView } = useInView({ triggerOnce: true });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const handleModeChange = () => {
    setIsSignIn(!isSignIn);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 400) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Header />

      <Grid
        container
        sx={{
          background:
            "linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 100%)",
          px: { xs: 4, md: 20, sm: 10 },
        }}
      >
        <Grid item sm={12} md={12} xs={12} lg={12}>
          <Grid container>
            <Grid item xs={12} md={6} sm={12} sx={{ margin: "auto 0" }}>
              <Grid container className="page">
                <Grid item xs={12} md={12} sm={12} lg={12} sx={{ pt: 8 }}>
                  <span style={{ visibility: "hidden" }}>G-WMFZC1ML2R</span>
                  <Typography
                    align="left"
                    sx={{
                      fontSize: { md: "43px", xs: "26px", sm: "35px" },
                      fontWeight: 500,
                      fontFamily: "Ubuntu",
                    }}
                  >
                    <span className="main-header-font main-header">
                      Modernize Your
                      <br />
                      <span>Investment Opportunity</span>
                    </span>
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ pt: 3 }}
                  className="main-header-font-sub"
                >
                  <Typography
                    align="left"
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Ubuntu",
                      color: "#3D3D3D",
                      fontSize: "15px",
                    }}
                    className="main-header-font-sub"
                  >
                    <span className="main-header-font-sub">
                      Stay ahead of the curve in the world of investment with
                      Arham Share. What's wrong if you get to meet all your
                      investment needs at one place?
                    </span>
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12} sx={{ pt: 2 }}>
                  <Typography
                    align="left"
                    color="#396A62"
                    style={{ fontWeight: "500", fontFamily: "Ubuntu" }}
                  >
                    It will only take a fraction of minutes to start your
                    investment journey.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {isSignIn ? (
                    <SignIn onModeChange={handleModeChange} />
                  ) : (
                    <SignUp onModeChange={handleModeChange} />
                  )}
                </Grid>

                {/* <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ pt: 2 }}
                  component={motion.div}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{
                    opacity: textInView ? 1 : 0,
                    y: textInView ? 0 : 50,
                  }}
                  transition={{ duration: 0.7 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      textAlign: "left",
                    }}
                  >
                    <img src={Group} alt="Logo" style={{ width: "85px" }} />
                    <Typography
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#939393",
                        fontFamily: "Ubuntu",
                        paddingLeft: "4px",
                      }}
                    >
                      Successfully completed the tremendously
                      <br />
                      open 5k+ eKYC process,
                    </Typography>
                  </Box>
                </Grid> */}
              </Grid>
            </Grid>

            <Grid item xs={12} md={2} sm={12}>
              <Box className="up-down">
                <img
                  src={Card2}
                  style={{
                    width: "80%",
                    paddingLeft: "30%",
                    paddingBottom: "40%",
                    marginTop: "-20px",
                  }}
                />
                <img
                  src={Card1}
                  style={{
                    width: "140%",
                    paddingLeft: "120%",
                    paddingBottom: "20%",
                  }}
                />

                <img src={Card3} style={{ width: "140%" }} />
              </Box>
            </Grid>

            <Grid item xs={12} md={2} sm={12} className="arham-image">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "75vh",
                }}
              >
                <img
                  src={Arham}
                  alt="Logo"
                  style={{
                    height: "75vh",
                    marginTop: "85px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PerksCards />
      <Modal />
      {/* <Channel /> */}
      <ArhamFeature />
      <Director />
      <Department />
      <Journey />
      <Footer />
      <QRCodeDisplay />
      {showScrollToTop && (
        <IconButton
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            opacity: showScrollToTop ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          }}
        >
          <ArrowUpwardIcon />
        </IconButton>
      )}
    </ThemeProvider>
  );
}
