import { Grid, Typography } from "@mui/material";
import React from "react";
import Header from "../Component/HomePage/Header";
import Frame from "../assets/images/Contact-Us/Frame.png";
import Fotter from "../Component/Footer";
import Enquire from "./Enquire";
import MapComponent from "./MapComponent";

function Index() {
  return (
    <>
      <Header />

      <Grid
        container
        sx={{
          background:
            "linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 30%)",
          px: { xs: 4, md: 20 },
        }}
      >
        <Grid container pt={6}>
          <Grid item xs={12} md={6} sx={{ margin: "auto 0" }}>
            <Typography
              sx={{
                fontSize: "50px",
                fontFamily: "Ubuntu",
                fontWeight: 500,
                // pt: 4,
                textAlign: "left",
              }}
            >
              Contact Us
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={Frame} style={{ width: "60%" }} />
          </Grid>
        </Grid>
        <Enquire />
        <MapComponent />
      </Grid>
      <Fotter />
    </>
  );
}

export default Index;
