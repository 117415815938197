import { Breadcrumbs, Card, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import Header from "../Component/HomePage/Header";
import Fotter from "../Component/Footer";

function Index() {
  const navigate = useNavigate();

  const handleBreadcrumbClick = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  return (
    <>
      <Header />

      <Grid
        container
        sx={{
          background:
            "linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 40%)",
          px: { xs: 4, md: 20 },
        }}
      >
        <Grid container pt={6}>
          <Grid item xs={12} md={6} sx={{ margin: "auto 0" }}>
            <Typography
              sx={{
                fontSize: "45px",
                fontFamily: "Ubuntu",
                fontWeight: 500,
                pt: 4,
                textAlign: "left",
              }}
            >
              Trading View Charts
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sx={{ pt: 4 }}>
            <Card
              sx={{
                fontSize: "18px",
                fontFamily: "Ubuntu",
                //   fontWeight: 500,
                textAlign: "left",
                p: 8,
                borderRadius: 10,
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", 
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Ubuntu",
                  // fontWeight: 500,
                  textAlign: "left",
                }}
              >
                See the Market. Seize the Opportunity: Arham share with
                TradingView. Next-level charts. <br />
                Next-level trading. Experience the difference
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Ubuntu",
                  textAlign: "left",
                  pt: 4,
                }}
              >
                A global charting and trading platform offering market insights,
                community of traders, and a{" "}
                <Link
                  href="https://www.tradingview.com/economic-calendar/"
                  target="_blank"
                  sx={{
                    color: "#396A62",
                    textDecoration: "none",
                    fontWeight: "bold",
                    "&:hover": {
                      color: "#000080",
                      textDecoration: "underline",
                    },
                  }}
                >
                  <br /> real-time economic calendar
                </Link>{" "}
                to track key financial events.
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Fotter />
    </>
  );
}

export default Index;
