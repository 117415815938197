import React from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  IconButton,
  Dialog,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function SebiScore({ open, handleClose }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          backgroundColor: "#2D5A53",
          padding: 3,
          color: "white",
          maxWidth: "400px",
          margin: "auto",
          borderRadius: "8px",
          position: "relative",
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: "8px",
            right: "10px",
            color: "White",
          }}
          onClick={handleClose} // Close the dialog when clicked
        >
          <CloseIcon />
        </IconButton>
        <Grid container>
          <Grid item md={12}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
                marginBottom: "8px",
                textAlign: "left",
                fontFamily: "Ubuntu",
              }}
            >
              Filing complaints on SCORES
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                marginBottom: "16px",
                textAlign: "left",
                color: "#f0b400",
                fontFamily: "Ubuntu",
              }}
            >
              Easy & quick
            </Typography>

            <ul style={{ padding: 0, textAlign: "left" }}>
              <li>
                <Typography sx={{ marginBottom: "8px", fontFamily: "Ubuntu" }}>
                  Register on SCORES portal
                </Typography>
              </li>
              <li>
                <Typography sx={{ marginBottom: "8px", fontFamily: "Ubuntu" }}>
                  Mandatory details for filing complaints on SCORES:
                </Typography>
              </li>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#99dad0",
                  color: "black",
                  margin: "4px",
                  fontFamily: "Ubuntu",
                  "&:hover": {
                    backgroundColor: "#99dad0",
                  },
                }}
              >
                Name
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#99dad0",
                  color: "black",
                  margin: "4px",
                  fontFamily: "Ubuntu",
                  "&:hover": {
                    backgroundColor: "#99dad0",
                  },
                }}
              >
                PAN
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#99dad0",
                  color: "black",
                  margin: "4px",
                  fontFamily: "Ubuntu",
                  "&:hover": {
                    backgroundColor: "#99dad0",
                  },
                }}
              >
                Address
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#99dad0",
                  color: "black",
                  margin: "4px",
                  fontFamily: "Ubuntu",
                  "&:hover": {
                    backgroundColor: "#99dad0",
                  },
                }}
              >
                Mobile Number
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#99dad0",
                  color: "black",
                  margin: "4px",
                  fontFamily: "Ubuntu",
                  "&:hover": {
                    backgroundColor: "#99dad0",
                  },
                }}
              >
                Email ID
              </Button>

              <li>
                <Typography
                  sx={{
                    marginTop: "16px",
                    marginBottom: "8px",
                    fontFamily: "Ubuntu",
                  }}
                >
                  Benefits:
                </Typography>
                <Typography sx={{ fontFamily: "Ubuntu", pl: 2 }}>
                  Effective communication
                </Typography>
                <Typography sx={{ fontFamily: "Ubuntu", pl: 2 }}>
                  Speedy redressal of the grievances
                </Typography>
              </li>

              <li style={{ marginTop: "16px" }}>
                <Typography sx={{ fontWeight: "bold", fontFamily: "Ubuntu" }}>
                  For Filling of Complaints please visit SCORE website:
                </Typography>
                <Link
                  href="https://scores.sebi.gov.in/"
                  target="_blank"
                  sx={{ color: "yellow", textDecoration: "underline" }}
                >
                  https://scores.sebi.gov.in/scores/Welcome.html
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default SebiScore;
