import React from "react";
import { Button, Grid, Typography, Box } from "@mui/material";

const doList = [
  {
    title: "Deal with registered intermediaries",
    description:
      "Ensure your broker or advisor is registered with SEBI or stock exchanges.",
  },
  {
    title: "Maintain proper documentation:",
    description:
      "Keep copies of all investment-related documents for future reference.",
  },
  {
    title: "Secure document delivery:",
    description:
      "Send important documents through reliable means like registered post.",
  },
  {
    title: "Verify transaction details",
    description:
      "Receive contract notes and account statements for every trade.",
  },
  {
    title: "Ensure sufficient funds:",
    description:
      "Have adequate funds in your account before buying securities.",
  },
  {
    title: "Settle dues promptly:",
    description:
      "Make payments to intermediaries through regular banking channels.",
  },
  {
    title: "Confirm security holdings",
    description: "Verify that you own securities before selling.",
  },
  {
    title: "Follow up diligently:",
    description:
      "Contact your broker or company promptly if documentation is delayed.",
  },
  {
    title: "Provide clear instructions:",
    description:
      "Give precise instructions to your broker or depository participant.",
  },
  {
    title: "Specify transaction mode",
    description: "Clearly indicate whether you prefer physical or demat mode.",
  },
  {
    title: "Conduct thorough research:",
    description:
      "Verify information from public media and conduct independent research before investing.",
  },
  {
    title: "Understand investment risks:",
    description: "Adopt trading strategies aligned with your risk tolerance.",
  },
  {
    title: "Exercise due diligence",
    description:
      "Carefully review the Risk Disclosure Document before registering with a broker.",
  },

  {
    title: "Be cautious of price spikes:",
    description:
      "Avoid impulsive decisions on stocks with sudden price increases.",
  },

  {
    title: "Expect market fluctuations:",
    description: "Remember that stock market investments are not guaranteed.",
  },
  {
    title: "Resolve disputes formally",
    description: "Lodge complaints through the nearest Arbitration Centre.",
  },
];

function Investor() {
  return (
    <Box sx={{ py: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontFamily: "Ubuntu",
              fontWeight: 500,
              textAlign: "center",
              mb: 4,
              fontSize: { xs: "35px", md: "40px", sm: "38px" },
            }}
          >
            <span style={{ color: "#396A62" }}>Do’s</span> and{" "}
            <span style={{ color: "#697523" }}>Dont’s</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#396A62",
              "&:hover": {
                backgroundColor: "#396A62",
              },
              textTransform: "none",
              fontFamily: "Ubuntu",
              borderRadius: "8px",
              fontSize: "large",
              width: "100%",
              maxWidth: "300px",
              margin: "0 auto",
              display: "block",
              mb: 4,
              cursor:"default"
            }}
          >
            <span style={{ fontSize: "20px" }}>Do's for Investors</span>
          </Button>
        </Grid>
        {doList.map((item, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Box sx={{ display: "flex", alignItems: "flex-start", mb: 4 }}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Ubuntu",
                  marginRight: "8px",
                  color: "#396A62",
                  fontSize: "2.5rem",
                  lineHeight: "2rem",
                }}
              >
                •
              </Typography>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Ubuntu",
                    fontWeight: 600,
                    fontSize: "16px",
                    textAlign: "left",
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Ubuntu",
                    textAlign: "left",
                  }}
                >
                  {item.description}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Investor;
