import { Button, CardMedia, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router";

function AllEvents() {
  const [events, setEvents] = useState([]);
  const [hoveredEventId, setHoveredEventId] = useState(null);
  const navigate = useNavigate();

  // Fetch event data from the API
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/arham_web/v1/fetch-events/`, {
        headers: {
          source: "arham_web",
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setEvents(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching event data:", error);
      });
  }, []);

  // Mouse event handlers
  const handleMouseEnter = (eventId) => {
    setHoveredEventId(eventId);
  };

  const handleMouseLeave = () => {
    setHoveredEventId(null);
  };
  const handleViewAllClick = (event) => {
    navigate(`/event/${event.event_id}`, {
      state: {
        event, // Pass event data via state
      },
    });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ pt: 6 }}>
          <Typography
            sx={{
              fontSize: "32px",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              textAlign: "left",
              color: "#396A62",
            }}
          >
            All Events
          </Typography>
        </Grid>

        {/* Category Buttons */}
        <Grid container spacing={2}></Grid>
      </Grid>

      {/* Events Grid */}
      <Grid container spacing={2} pt={4}>
        {events.map((event, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={event.event_id}>
            <div
              style={{
                position: "relative",
                borderRadius: "20px",
                overflow: "hidden",
                transition: "all 0.3s ease-in-out",
              }}
              onMouseEnter={() => handleMouseEnter(event.event_id)}
              onMouseLeave={handleMouseLeave}
            >
              {event.images && event.images.length > 0 && (
                <CardMedia
                  component="img"
                  src={`data:image/png;base64,${event.images[0].file_data}`}
                  alt={`Event ${index + 1}`}
                  height="300"
                  style={{
                    borderRadius: 20,
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              )}

              {/* Button with fade-in effect */}
              <Button
                variant="contained"
                onClick={() => handleViewAllClick(event)} 
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#396A62",
                  color: "#fff",
                  opacity: hoveredEventId === event.event_id ? 1 : 0,
                  transition: "opacity 0.5s ease",
                  pointerEvents:
                    hoveredEventId === event.event_id ? "auto" : "none",
                  "&:hover": {
                    backgroundColor: "#2b5b4f",
                  },
                }}
              >
                View All
              </Button>
            </div>

            {/* Event details */}
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontWeight: 600,
                color: "#396A62",
                textAlign: "left",
                pl: 2,
              }}
            >
              {event.event_duration}
            </Typography>
            <Typography
              gutterBottom
              sx={{
                fontFamily: "Ubuntu",
                fontWeight: 600,
                fontSize: "18px",
                textAlign: "left",
                pl: 2,
              }}
            >
              {event.event_header}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default AllEvents;
