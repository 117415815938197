import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router";

function Events() {
  const [events, setEvents] = useState([]);
  const [videos, setVideos] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  // Function to fetch events from the API
  const fetchEvents = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/arham_web/v1/fetch-events/`,
        {
          method: "GET",
          headers: {
            source: "arham_web",
          },
        }
      );

      if (response.ok) {
        const res = await response.json();
        // Check if the response data structure matches what we expect
        if (res && res.data && Array.isArray(res.data)) {
          setEvents(res.data);
        } else {
          console.error("Unexpected API response structure", res);
        }
      } else {
        console.error("Failed to fetch events");
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const fetchVideos = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/arham_web/v1/fetch-video-events/`,
        {
          method: "GET",
          headers: {
            source: "arham_web",
          },
        }
      );

      if (response.ok) {
        const res = await response.json();
        // Check if the response contains video data
        if (res && res.data && Array.isArray(res.data)) {
          setVideos(res.data);
        } else {
          console.error("Unexpected API response structure", res);
        }
      } else {
        console.error("Failed to fetch videos");
      }
    } catch (err) {
      console.error("Error fetching videos:", err);
    }
  };

  // Fetch events when the component mounts
  useEffect(() => {
    fetchEvents();
    fetchVideos();
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 2) % videos.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 2 + videos.length) % videos.length
    );
  };

  const handleViewAll = () => {
    navigate("/view-all");
  };

  return (
    <div>
      <Grid container spacing={2} pt={4}>
        {/* First event as the banner image */}
        <Grid container spacing={2}>
          {/* Main event */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: "relative",
                width: "90%",
                height: "auto",
                display: "inline-block",
              }}
            >
              {/* Background Image */}
              {events[0]?.images && events[0].images.length > 0 ? (
                <img
                  src={`data:image/png;base64,${events[0].images[0].file_data}`}
                  alt={events[0].event_header}
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "15px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Typography>No event image available</Typography>
              )}

              {/* Overlay Details */}
              <Box
                sx={{
                  position: "absolute",
                  top: "10%",
                  left: "5%",
                  color: "white",
                  padding: { xs: "5px", md: "10px" },
                }}
              >
                {/* Event Header */}
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "28px", md: "42px" },
                    padding: "5px",
                    borderRadius: "8px",
                    textAlign: "left",
                  }}
                >
                  {events[0]?.event_header || "Event Header"}
                </Typography>

                {/* Event Date */}
                <Typography
                  variant="h6"
                  sx={{
                    backgroundColor: "rgba(0, 128, 0, 0.7)",
                    padding: "8px",
                    marginTop: "10px",
                    borderRadius: "8px",
                    width: { xs: "70%", md: "60%" },
                    textAlign: "left",
                  }}
                >
                  {events[0]?.event_duration || "Event Duration"}
                </Typography>
              </Box>

              {/* Event Location at the bottom */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "90%",
                  color: "white",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "left",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  {events[0]?.event_location || "Event Location"}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Other event images */}
          <Grid item xs={12} md={6} p={1}>
            {events.length > 1 ? (
              events.slice(1, 3).map((event, index) => (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "300px",
                    display: "inline-block",
                    marginBottom: "0px",
                  }}
                >
                  {/* Event Image */}
                  {event.images && event.images.length > 0 ? (
                    <img
                      src={`data:image/png;base64,${event.images[0].file_data}`}
                      alt={`Event ${index + 1}`}
                      style={{
                        width: "100%", // Full width of the container
                        height: "100%", // Full height of the container
                        borderRadius: "15px",
                        objectFit: "cover", // Ensures image maintains aspect ratio and fills container
                      }}
                    />
                  ) : (
                    <Typography>No event image available</Typography>
                  )}

                  {/* Event Details */}
                  <Box
                    sx={{
                      position: "absolute",
                      top: "10%",
                      left: "5%",
                      color: "white",
                      padding: { xs: "5px", md: "10px" },
                    }}
                  >
                    {/* Event Header */}
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: { xs: "22px", md: "30px" },
                        padding: "5px",
                        borderRadius: "8px",
                        textAlign: "left",
                      }}
                    >
                      {event.event_header}
                    </Typography>

                    {/* Event Duration */}
                    <Typography
                      variant="body2"
                      sx={{
                        backgroundColor: "rgba(0, 128, 0, 0.7)",
                        padding: "8px",
                        marginTop: "10px",
                        borderRadius: "8px",
                        width: { xs: "70%", md: "60%" },
                        textAlign: "left",
                      }}
                    >
                      {event.event_duration}
                    </Typography>
                  </Box>

                  {/* Event Location */}
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "10px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: "90%",
                      color: "white",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        textAlign: "left",
                        padding: "10px",
                        borderRadius: "8px",
                      }}
                    >
                      {event.event_location}
                    </Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography>No other events available</Typography>
            )}
          </Grid>
        </Grid>

        {/* View All Button */}
        <Grid item xs={12} md={12}>
          <Box>
            <Button
              onClick={handleViewAll}
              sx={{
                backgroundColor: "#396A62",
                "&:hover": {
                  backgroundColor: "#0B2A29",
                },
                fontFamily: "Ubuntu",
                border: "1px #0B2A29",
                borderRadius: "8px",
                height: "38px",
                width: "100px",
                textTransform: "none",
                color: "white",
              }}
            >
              View All
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Video Section */}
      <Grid container spacing={2} pt={4}>
        {videos.length > 0 ? (
          <Grid item xs={12} md={6}>
            {/* Video Carousel */}
            <Box>
              <video
                key={currentIndex}
                src={videos[currentIndex]?.videoUrl || ""}
                controls
                width="100%"
              />
            </Box>

            {/* Carousel Controls */}
            <Box mt={2}>
              <Button onClick={handlePrev} disabled={videos.length <= 2}>
                Prev
              </Button>
              <Button onClick={handleNext} disabled={videos.length <= 2}>
                Next
              </Button>
            </Box>
          </Grid>
        ) : (
          <Typography>No videos available</Typography>
        )}
      </Grid>
    </div>
  );
}

export default Events;
