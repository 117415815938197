import React, { useState } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Download() {
  const [selectedCard, setSelectedCard] = useState();
  const navigate = useNavigate();

  const cards = [
    {
      title: "Technical Downloads",
      description: "Download all the essential apps, trading terminals, and support tools to enhance your investment experience.",
      path: "/technical",
      section: "technical",
    },
    {
      title: "KYC Forms",
      description: "Access and download all necessary KYC forms, including account opening, modification, and segment addition forms.",
      path: "/kyc-forms",
      section: "kyc_forms",
    },
    // {
    //   title: "Others",
    //   description: "Access our company's policy and corporate governance forms here.",
    //   path: "/others",
    //   section: "others",
    // },
  ];

  const handleViewClick = (index) => {
    setSelectedCard(index);
    navigate(cards[index].path);
  };

  return (
    <Grid container spacing={2}>
      {cards.map((card, index) => (
        <Grid item xs={12} md={6} key={index}>
          <Card
            sx={{
              p: 2,
              borderRadius: 4,
              backgroundColor: selectedCard === index ? "#396A62" : "#fff",
              color: selectedCard === index ? "#fff" : "#396A62",
              cursor: "pointer",
              height: "120px",
            }}
            onClick={() => handleViewClick(index)}
          >
            <Typography
              sx={{
                fontSize: "17px",
                fontFamily: "Ubuntu",
                textAlign: "left",
                fontWeight: 500,
              }}
            >
              {card.title}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Ubuntu",
                textAlign: "left",
                pt: 1,
                color: selectedCard === index ? "#fff" : "#3D3D3D",
              }}
            >
              {card.description}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontWeight: 500,
                textAlign: "left",
                pt: 1, 
                color: selectedCard === index ? "#fff" : "#396A62",
              }}
            >
              View →
            </Typography>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default Download;
