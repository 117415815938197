import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import Header from "../Component/HomePage/Header";
import Fotter from "../Component/Footer";
import Events from "./Events";

function Index() {
  const navigate = useNavigate();

  const handleBreadcrumbClick = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  return (
    <>
      <Header />

      <Grid
        container
        sx={{
          background:
            " linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 40%)",
            px: { xs: 4, md: 4, lg: 18 },
        }}
      >
        <Grid container pt={6}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ margin: "auto 0", textAlign: "center" }}
          >
            <Typography
              sx={{
                fontSize: "55px",
                fontFamily: "Ubuntu",
                fontWeight: 500,
                // pt: 4,
                // textAlign: "left",
              }}
            >
              Events
            </Typography>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator=">"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Link
                color="inherit"
                href="/"
                onClick={(event) => handleBreadcrumbClick(event, "/")}
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  fontFamily: "Ubuntu",
                  fontWeight: 500,
                  fontSize: "25px",
                  color: "#3D3D3D",
                }}
              >
                Home
              </Link>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontWeight: 400,
                  fontSize: "25px",
                  color: "#3D3D3D",
                }}
              >
                Events
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  fontSize: "35px",
                  fontFamily: "Ubuntu",
                  fontWeight: 500,
                  pt: 8,
                  textAlign: "center",
                  color: "#396A62",
                }}
              >
                Event <span style={{ color: "black" }}>Gallery</span>
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Ubuntu",
                  pt: 1,
                  textAlign: "center",
                }}
              >
                Arham Share Events Gallery, a place where the brightest spots of
                our most wonderful events and summits are available. With a
                <br />
                handpicked selection of images, videos, and the most vital
                information from our special events held in different cities.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Events />
      </Grid>
      <Fotter />
    </>
  );
}

export default Index;
