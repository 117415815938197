import {
  Box,
  Button,
  Grid,
  Select,
  MenuItem,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "../Component/HomePage/Header";
import Fotter from "../Component/Footer";
import axios from "axios";

function Index() {
  const [isLoading, setIsLoading] = useState(false); 
  const [magazines, setMagazines] = useState([]);
  const currentYear = new Date().getFullYear(); // Get the current year
  const [selectedYear, setSelectedYear] = useState(currentYear); // Set current year as default
  const years = Array.from(new Array(1), (val, index) => currentYear - index); 

  // Fetch magazine data from the API
  useEffect(() => {
    const fetchMagazines = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/arham_web/v1/fetch-magazines/`,
          {
            headers: {
              source: "arham_web",
            },
          }
        );
        if (response.data.status === "success") {
          setMagazines(response.data.data); 
        } else {
          console.error("Error fetching magazines:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching magazines:", error);
      }
    };

    fetchMagazines();
  }, []);

  // Filter magazines based on the selected year
  const filteredMagazines = magazines.filter((magazine) => {
    const magazineYear = new Date(magazine.magazine_date).getFullYear();
    return magazineYear === selectedYear;
  });

  const downloadMagazine = async (file_name) => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `http://192.168.119.192:3020/arham_web/v1/download-magazines/?magazine_name=${encodeURIComponent(
          file_name
        )}`,
        {
          headers: {
            source: "arham_web",
          },
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error.message);
      alert(`Failed to download file: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Header />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        container
        sx={{
          background:
            "linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 100%)",
          px: { xs: 4, md: 4, lg: 18 },
        }}
      >
        <Grid item md={12}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "Ubuntu",
              textAlign: "center",
              fontSize: { xs: "25px", md: "40px" },
              pt: 4,
            }}
          >
            Monthly Magazine
          </Typography>
        </Grid>
        <Box
          sx={{
            marginTop: 4,
            backgroundColor: "#DEEBE9",
            px: 2,
            borderRadius: 4,
          }}
        >
          <Typography
            component="label"
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              fontFamily: "Ubuntu",
              color: "#000",
              px: 1,
            }}
          >
            Select Year
          </Typography>
          <Select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              fontFamily: "Ubuntu",
              padding: "10px 20px",
              borderRadius: "8px",
              boxShadow: "none",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: selectedYear ? "transparent" : "initial",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#396A62",
              },
              backgroundColor: selectedYear ? "#DEEBE9" : "#fff",
            }}
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Grid container spacing={2} pt={2}>
          {filteredMagazines.map((magazine) => (
            <Grid item md={3} key={magazine.magazine_id}>
              <Box sx={{ position: "relative", width: "100%" }}>
                {/* Image */}
                {magazine.files.map((file) => {
                  if (file.file_type.includes("image/")) {
                    return (
                      <img
                        key={file.file_name}
                        src={`data:${file.file_type};base64,${file.file_data}`}
                        alt={file.file_name}
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                        }}
                      />
                    );
                  }
                  return null;
                })}

                {/* Overlay Box */}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "10px",
                    right: "40px",
                    width: "100%",
                    height: "100%",
                    color: "#fff",
                    borderRadius: "10px",
                    padding: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    fontFamily: "Ubuntu",
                  }}
                >
                  {/* Magazine Date */}
                  <Typography sx={{ fontWeight: "bold" }}>
                    {magazine.magazine_date}
                  </Typography>

                  {/* Download PDF Button */}
                  {magazine.files.map((file) => {
                    if (file.file_type === "application/pdf") {
                      return (
                        <Button
                          key={file.file_name}
                          onClick={() =>
                            downloadMagazine(file.file_name, file.file_data)
                          }
                          sx={{
                            color: "#fff",
                            marginTop: 1,
                            fontFamily: "Ubuntu",
                          }}
                        >
                          Read More →
                        </Button>
                      );
                    }
                    return null;
                  })}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Fotter />
    </>
  );
}

export default Index;
