import React, { useState } from "react";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import axios from "axios";

function InvestorGrievance() {
  const [loading, setLoading] = useState(false);
  const address = process.env.REACT_APP_ADDRESS;

  const rows = [
    {
      details: "Client Servicing /Customer Care",
      person: "Pratik Lineswala",
      address: address,
      contact: "0261-6794000",
      email: "contact@arhamshare.com",
      hours: "9 AM to 6 PM",
    },
    {
      details: "Head of Client Servicing /Customer Care",
      person: "Rakesh Patel",
      address: address,
      contact: "0261-6794009",
      email: "rakesh@arhamshare.com",
      hours: "9 AM to 6 PM",
    },
    {
      details: "Compliance Officer / Head of Customer Care",
      person: "Priyank Mehta",
      address: address,
      contact: "0261-6794005",
      email: "mehta_priyank@ymail.com",
      hours: "9 AM to 6 PM",
    },
    {
      details: "CEO",
      person: "Naresh Mehta",
      address: address,
      contact: "0261-6794008",
      email: "naresh.m.mehta@gmail.com",
      hours: "9 AM to 6 PM",
    },
  ];

  // Function to handle PDF download
  const downloadPDF = async (fileName) => {
    setLoading(true);
    const apiUrl = `${
      process.env.REACT_APP_API_BASE_URL
    }/arham_web/v1/fetch-pdfs/?pdf_name=${encodeURIComponent(fileName)}`;

    try {
      const response = await axios.get(apiUrl, {
        responseType: "blob", // Important to set the response type to 'blob'
      });

      // Create a URL from the response blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Set the file name for the download
      document.body.appendChild(link);
      link.click();
      console.log("fileName", fileName);

      // Clean up
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item md={12} sm={12}>
          <Typography
            sx={{
              fontSize: "25px",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              textAlign: "left",
              color: "#396A62",
              pt: { xs: 2, md: 4 },
            }}
          >
            INVESTOR GRIEVANCE REDRESSAL
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              textAlign: "left",
              pt: 1,
            }}
          >
            Escalation Matrix
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          pt={2}
          sx={{
            overflowX: "auto",
          }}
        >
          <TableContainer component={Paper}>
            <Table
              sx={{
                borderCollapse: "separate",
                borderSpacing: "0",
                border: "1px solid #396A62",
                borderRadius: "10px",
                minWidth: "650px",
                overflow: "hidden",
              }}
            >
              <TableHead style={{ backgroundColor: "#396A62" }}>
                <TableRow>
                  <TableCell style={{ color: "white", textAlign: "left" }}>
                    Details of
                  </TableCell>
                  <TableCell style={{ color: "white", textAlign: "left" }}>
                    Contact Person
                  </TableCell>
                  <TableCell style={{ color: "white", textAlign: "left" }}>
                    Address
                  </TableCell>
                  <TableCell style={{ color: "white", textAlign: "left" }}>
                    Contact No
                  </TableCell>
                  <TableCell style={{ color: "white", textAlign: "left" }}>
                    Email Id
                  </TableCell>
                  <TableCell style={{ color: "white", textAlign: "left" }}>
                    Working Hours
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        fontFamily: "Ubuntu",
                        textAlign: "left",
                        border: "1px solid gray",
                      }}
                    >
                      {row.details}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "Ubuntu",
                        textAlign: "left",
                        border: "1px solid gray",
                      }}
                    >
                      {row.person}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Ubuntu",
                        textAlign: "left",
                        border: "1px solid gray",
                      }}
                    >
                      {row.address}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Ubuntu",
                        textAlign: "left",
                        border: "1px solid gray",
                      }}
                    >
                      {row.contact}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Ubuntu",
                        textAlign: "left",
                        border: "1px solid gray",
                      }}
                    >
                      <a
                        href={`mailto:${row.email}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {row.email}
                      </a>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Ubuntu",
                        textAlign: "left",
                        border: "1px solid gray",
                      }}
                    >
                      {row.hours}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item md={12} sm={12} pt={2}>
          <Typography
            sx={{
              fontSize: "15px",
              fontFamily: "Ubuntu",
              textAlign: "left",
              pt: 1,
            }}
          >
            * Monday to Friday 9:00AM to 06:00PM & Saturday 11:00AM to 04:00PM
            Close on Sunday & Exchange Holiday.
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              fontFamily: "Ubuntu",
              textAlign: "left",
              pt: 1,
            }}
          >
            In the absence of a response or if your complaint is not addressed
            to your satisfaction, you may lodge a complaint with SEBI at:
          </Typography>

          <ul>
            <li style={{ textAlign: "left" }}>
              <a
                href="https://scores.gov.in/scores/Welcome.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "black",
                  wordBreak: "break-all",
                }}
              >
                SEBI at https://scores.gov.in/scores/Welcome.html
              </a>
            </li>

            <li style={{ textAlign: "left", paddingTop: "8px" }}>
              <a
                href="https://investorhelpline.nseindia.com/NICEPLUS"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "black",
                  wordBreak: "break-all",
                }}
              >
                Exchange NSE at https://investorhelpline.nseindia.com/NICEPLUS
              </a>
            </li>

            <li style={{ textAlign: "left", paddingTop: "8px" }}>
              <a
                href="https://bsecrs.bseindia.com/ecomplaint/frmInvestorHome.aspx"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "black",
                  wordBreak: "break-all",
                }}
              >
                Exchange BSE at
                https://bsecrs.bseindia.com/ecomplaint/frmInvestorHome.aspx
              </a>
            </li>

            <li
              style={{
                textAlign: "left",
                paddingTop: "8px",
                wordBreak: "break-all",
              }}
            >
              <a
                href="https://www.mcxindia.com/Investor-Services"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "black",
                  wordBreak: "break-all",
                }}
              >
                Exchange MCX at https://www.mcxindia.com/Investor-Services
              </a>
            </li>

            <li style={{ textAlign: "left", paddingTop: "8px" }}>
              <Typography
                onClick={() =>
                  downloadPDF("INVESTOR GRIEVANCE REDRESSAL POLICY.pdf")
                }
                style={{
                  textDecoration: "none",
                  color: "black",
                  wordBreak: "break-all",
                  cursor: "pointer",
                }}
              >
                Investor grievance redressal policy
              </Typography>
            </li>
          </ul>
        </Grid>
      </Grid>
    </>
  );
}

export default InvestorGrievance;
