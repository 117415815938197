import {
  Backdrop,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../Component/HomePage/Header";
import InvestorImg from "../assets/images/Contact-Us/investor.png";
import Investor from "./Investor";
import DontInvestor from "./DontInvestor";

function Index() {
  const [loading, setLoading] = useState(false);
  const [pdfFiles, setPdfFiles] = useState([]);

  // Fetch list of PDFs from API
  useEffect(() => {
    const fetchPdfFiles = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/arham_web/v1/fetch-all-identifiers/`,
          {
            method: "GET",
            headers: {
              source: "arham_web",
            },
          }
        );
        const result = await response.json();

        console.log("API Response:", result);

        if (Array.isArray(result.data)) {
          setPdfFiles(result.data);
        } else {
          console.error("Expected an array but got:", result.data);
        }
      } catch (error) {
        console.error("Error fetching PDF list:", error.message);
        alert("Failed to fetch PDF list");
      } finally {
        setLoading(false);
      }
    };

    fetchPdfFiles();
  }, []);

  // Function to download PDF
  const downloadPDF = async (file_identifier) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/arham_web/v1/fetch-pdf-by-identifier/?file_identifier=${encodeURIComponent(
          file_identifier
        )}`,
        {
          method: "GET",
          headers: {
            source: "arham_web",
          },
        }
      );
      console.log("response", response);

      if (!response.ok) {
        throw new Error(`Failed to fetch the file. Status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_identifier);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error.message);
      alert(`Failed to download file: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Filtered PDF files to only show specific ones
  const filteredPdfFiles = pdfFiles.filter((file) =>
    [
      "Annexure_A_Investor Charter for DP",
      "Annexure-B-Investor Charter",
      "Aug 24 Annexure – B - Investor Complain Data for Stock Broker",
      "Sep 23 Commodity_Annexure – B - Investor Complain Data for Stock Broker",
      "Annexure – A - Investor Charter for Stock Broker",
      "Aug 24 Annexure – C - Investor Complain Data for DP",
    ].includes(file.file_identifier)
  );

  const chunkArray = (array, chunkSize) => {
    const results = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      results.push(array.slice(i, i + chunkSize));
    }
    return results;
  };

  return (
    <>
      <Header />

      {/* Show CircularProgress with Backdrop when loading */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid
        container
        sx={{
          background:
            "linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 50%)",
          px: { xs: 4, md: 4, lg: 18 },
        }}
      >
        <Grid container pt={6} spacing={2}>
          <Grid item xs={12} md={6} sx={{ margin: "auto 0" }}>
            <Typography
              sx={{
                fontSize: { md: "50px", xs: "35px", sm: "40px" },
                fontFamily: "Ubuntu",
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              Investor’s Charter
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={InvestorImg} style={{ width: "60%" }} alt="Investor" />
          </Grid>
        </Grid>

        <Grid container pt={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {filteredPdfFiles.length > 0 ? (
                  chunkArray(filteredPdfFiles, 3).map((chunk, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {chunk.map((file, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            fontFamily: "Ubuntu",
                            textAlign: "left",
                            border: "1px solid gray",
                          }}
                        >
                          <Link
                            onClick={() => downloadPDF(file.file_identifier)}
                            sx={{
                              cursor: "pointer",
                              textDecoration: "none",
                              color: "#697523",
                              display: "block",
                              fontFamily: "Ubuntu",
                              fontSize: "18px",
                            }}
                          >
                            {file.pdf_name.replace(".pdf", "")}
                          </Link>
                        </TableCell>
                      ))}
                      {chunk.length < 3 &&
                        [...Array(3 - chunk.length)].map((_, emptyIndex) => (
                          <TableCell key={`empty-${emptyIndex}`} />
                        ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>
                      No Investor Charter files available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Investor />
      </Grid>
      <DontInvestor />
    </>
  );
}

export default Index;
